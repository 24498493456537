<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      max-width="600px"
      scrollable>
      <v-card>
        <v-card-title>
          <span class="submit-dialog__title">
            <span class="text-h5">{{year}}</span>年
            <span class="text-h5">{{month}}</span>月
          </span>
          <v-spacer/>
          <v-icon @click="isOpen = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td style="min-width: 100px">
                    送信日時
                  </td>
                  <td>
                    {{date}}
                  </td>
                </tr>
                <tr>
                  <td>
                    送信先
                  </td>
                  <td class="pt-1 pb-1">
                    <p class="mb-0"
                      v-for="item in address"
                      :key="item">
                      {{item}}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    コメント
                  </td>
                  <td class="pt-1 pb-1">
                    {{comment}}
                  </td>
                </tr>
                <tr>
                  <td>
                    提出物
                  </td>
                  <td class="pt-1 pb-2">
                    <v-chip small class="mt-1"
                      v-for="item in documents"
                      :key="item">
                      {{item.substring(0,4)}}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td>
                    送信結果
                  </td>
                  <td>
                    {{result}}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      isOpen: false,
      year: null,
      month: null,
      date: null,
      address: null,
      comment: null,
      result: null,
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove')
        this.year = this.dialog.submitHistory.month.format('YYYY')
        this.month = this.dialog.submitHistory.month.format('M')
        this.date = this.dialog.submitHistory.date.format('YYYY/MM/DD HH:mm:ss')
        this.address = this.dialog.submitHistory.address
        this.comment = this.dialog.submitHistory.comment
        this.documents = this.dialog.submitHistory.documents
        this.result = this.dialog.submitHistory.errorCode == '0'?'成功':'エラー'
        this.isOpen = true
      },
    },
    created() {
      this.common.eventBus.$on('SubmitHistoryDialog.open', this.initialize)
    },
  }
</script>

<style scoped>
  .submit-dialog__title {
    font-size: medium;
  }
</style>
