<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      persistent
      fullscreen
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="travels-dialog__title">
            <span class="text-h5">{{common.select.year}}</span>年
            <span class="text-h5">{{common.select.month}}</span>月
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="isOpen = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle class="mt-1 pb-2">
          定期券
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <CommuterPassListItems :common="common" :data="data" :dialog="dialog" :displayItem="{commuterPass: commuterPass}"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
        <v-spacer/>
          <v-btn :color="common.color.commuterpass" dark block
            @click="openCommuterPassDialog">
            <v-icon dark small left>
              mdi-plus
            </v-icon>
            新たに定期券を追加する
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import CommuterPassListItems from './partial/CommuterPassListItems.vue';

  export default {
    props: ["common", "data", "dialog"],
    components: {
      CommuterPassListItems,
    },
    data: () => ({
      isShow: true,
      isOpen: false,
      commuterPass: [],
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove');
        this.commuterPass = this.data.commuterPass.thisMonth
        this.isOpen = true
      },
      reload() {
        this.commuterPass = this.data.commuterPass.thisMonth
        this.isShow = false
        this.isShow = true
      },
      openCommuterPassDialog() {
        this.dialog.year = this.common.select.year
        this.dialog.month = this.common.select.month
        this.dialog.commuterPass = {}
        this.common.eventBus.$emit('CommuterPassDialog.open')
      },
    },
    created() {
      this.common.eventBus.$on('CommuterPassList.open', this.initialize);
      this.common.eventBus.$on('CommuterPassList.reload', this.reload);
    },
  }
</script>

<style scoped>
  .travels-dialog__title {
    font-size: medium;
  }
</style>
