<template>
  <div class="worktime-list-items ml-1" 
    v-if="
      isShow &&
      displayItem && 
      displayItem.worktimes && 
      displayItem.worktimes.length">
    <v-list-item
      class="worktime-list-items__item mb-1 mt-1"
      v-for="(worktime, index) in displayItem.worktimes"
      :key="'worktime-' + index"
      three-line
      @click="openWorkTimeDialog(index)"
      >
      <v-list-item-content class="worktime-list-items__item__content"
        v-if="worktime.daytype == 0">
        <v-list-item-subtitle >{{worktime.remarks}}</v-list-item-subtitle>
        <v-list-item-title :class="{'mt-1': worktime.remarks}">
          <span>{{worktime.starttime.format("H:mm")}}</span>
          <span class="ma-1">〜</span>
          <span>{{worktime.endtime.format("H:mm")}}</span>
          <span class="worktime-list-items__item__breaktime">
            （休憩：<span class="mr-1">{{worktime.breaktime}}</span>h）</span>
        </v-list-item-title>
        <v-list-item-subtitle class="mt-1">{{worktime.totaltime}}<span class="ml-1">h</span></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content class="worktime-list-items__item__content"
        v-else>
        <v-list-item-title 
          :class="{
            'worktime-list-items__item__title--holiday': worktime.daytype == 1,
            'worktime-list-items__item__title--vacation': worktime.daytype == 2}">
          <span>
            {{common.daytype[worktime.daytype]}}
            <span  class="worktime-list-items__item__remarks"
              v-if="worktime.remarks">
              （{{worktime.remarks}}）
            </span>
          </span>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action style="align-self: center;">
        <v-btn icon @click.stop="remove(worktime.date)">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>        
</template>

<script>

  export default {
    props: ["common", "data", "dialog", "displayItem"],
    data: () => ({
      isShow: true,
    }),
    methods: {
      openWorkTimeDialog(index) {
        this.dialog.year = this.displayItem.date.get("year")
        this.dialog.month = this.displayItem.date.get("month") + 1
        this.dialog.day = this.displayItem.date.get("date")
        this.dialog.worktime = this.displayItem.worktimes[index]
        this.common.eventBus.$emit('WorkTimeDialog.open')
      },
      remove(date) {
        this.deleteWorkTime(date)
        .then(() => {
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
      reload() {
        this.isShow = false
        this.isShow = true
      },
    },
    created() {
      this.common.eventBus.$on('WorktimeListItems.reload', this.reload)
    },
}
</script>

<style scoped>
  .worktime-list-items {
    text-align: left;
  }
  .worktime-list-items__item {
    border-left: 5px solid var(--worktimeColor);
  }
  .worktime-list-items__item__content {
    color: var(--textColor-default);
  }
  .worktime-list-items__item__breaktime {
    font-size: small;
  }
  .worktime-list-items__item__title--holiday {
    color: var(--textColor-holiday);
  }
  .worktime-list-items__item__title--vacation {
    color: var(--textColor-vacation);
  }
  .worktime-list-items__item__remarks {
    font-size: small;
  }

</style>
