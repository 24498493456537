import moment from 'moment'
import abstractApi from './abstract-api'

let API_URL = "/std-working-hours-api"

let api = {
      find: API_URL + "/find",
      register: API_URL + "/register",
      delete: API_URL + "/delete",
    }

export default{
  get(month, token) {
    return abstractApi.executeApi(api.find, {baseMonth: month}, token)
  },
  set(clientSideObject, token) {
    let serverSideObject = this.toServerSideObject(clientSideObject)
    return abstractApi.executeApi(api.register, serverSideObject, token)
  },
  delete(month, token) {
    return abstractApi.executeApi(api.delete, {baseMonth: month}, token)
  },
  toClientSideObjects(serverSideObjects) {
    let clientSideObjects = []
    if (serverSideObjects) {
      for(let serverSideObject of serverSideObjects) {
        let clientSideObject = {
          month: serverSideObject.baseMonth,
          starttime: moment(serverSideObject.punchIn),
          endtime: moment(serverSideObject.punchOut),
          breaktime: serverSideObject.breakTime,
        }
        clientSideObjects.push(clientSideObject)
      }
    }
    return clientSideObjects
  },
  toServerSideObject(clientSideObject) {
    let serverSideObject = {}
    if (clientSideObject) {
      serverSideObject = {
        baseMonth: clientSideObject.month,
        punchIn: clientSideObject.starttime.format("YYYY-MM-DD HH:mm:ss"),
        punchOut: clientSideObject.endtime.format("YYYY-MM-DD HH:mm:ss"),
        breakTime: clientSideObject.breaktime,
      }
    }
    return serverSideObject
  },
}