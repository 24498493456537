import moment from 'moment'
import abstractApi from './abstract-api'

let API_URL = "/working-hours-api"
let HISTORY_API_URL = "/working-hours-history-api"

let api = {
      find: API_URL + "/find",
      add: API_URL + "/add",
      edit: API_URL + "/edit",
      register: API_URL + "/register",
      delete: API_URL + "/delete",
      deleteByMonth: API_URL + "/delete/month",
      excel: API_URL + "/excel",
      download: API_URL + "/download",
      history: {
        find: HISTORY_API_URL + "/find",
        add: HISTORY_API_URL + "/add",
        delete: HISTORY_API_URL + "/delete",
      }
    }

export default{
  get(month, token) {
    return abstractApi.executeApi(api.find, {month: month}, token)
  },
  set(clientSideObject, token) {
    let serverSideObject = this.toServerSideObject(clientSideObject)
    return abstractApi.executeApi(api.register, serverSideObject, token)
  },
  delete(date, token) {
    return abstractApi.executeApi(api.delete, {baseDate: date.format("YYYY-MM-DD")}, token)
  },
  excel(month, token) {
    return abstractApi.executeApi(api.excel, {month: month}, token)
  },
  setMonthlyData(clientSideObjects, token) {
    let promiseList = []
    return new Promise((resolve) => {
      if (clientSideObjects) {
        for (let clientSideObject of clientSideObjects) {
          promiseList.push(this.set(clientSideObject, token))
        }
      }
      Promise.all(promiseList)
      .then(() => {
        resolve()
      })
    })
  },
  setHistory(clientSideObject, token) {
    let serverSideObject = this.toServerSideObject(clientSideObject)
    return abstractApi.executeApi(api.history.add, serverSideObject, token)
  },
  getHistory(token) {
    return abstractApi.executeApi(api.history.find, undefined, token)
  },
  deleteHistory(id, token) {
    return abstractApi.executeApi(api.history.delete, {historyId: String(id)}, token)
  },
  toClientSideObjects(serverSideObjects) {
    let clientSideObjects = {}
    if (serverSideObjects) {
      for(let serverSideObject of serverSideObjects) {
        let clientSideObject = {
          date: moment(serverSideObject.baseDate),
          daytype: serverSideObject.holidayType,
          starttime: serverSideObject.punchIn ? moment(serverSideObject.punchIn) : undefined,
          endtime: serverSideObject.punchOut ? moment(serverSideObject.punchOut) : undefined,
          breaktime: serverSideObject.breakTime,
          remarks: serverSideObject.remarks,
        }
        clientSideObject.totaltime = this.getTotalTime(clientSideObject.starttime, clientSideObject.endtime, clientSideObject.breaktime)
        clientSideObjects[clientSideObject.date.format("D")] = clientSideObject
      }
    }
    return clientSideObjects
  },
  toServerSideObjects(clientSideObjects) {
    let serverSideObjects = []
    if (clientSideObjects) {
      for(let clientSideObject of clientSideObjects) {
        let serverSideObject = this.toServerSideObject(clientSideObject)
        serverSideObjects.push(serverSideObject)
      }
    }
    return serverSideObjects
  },
  toServerSideObject(clientSideObject) {
    let serverSideObject = {}
    if (clientSideObject) {
      serverSideObject = {
        baseDate: clientSideObject.date.format("YYYY-MM-DD"),
        holidayType: clientSideObject.daytype,
        punchIn: clientSideObject.starttime ? clientSideObject.starttime.format("YYYY-MM-DD HH:mm:ss") : undefined,
        punchOut: clientSideObject.endtime ? clientSideObject.endtime.format("YYYY-MM-DD HH:mm:ss") : undefined,
        breakTime: clientSideObject.breaktime,
        remarks: clientSideObject.remarks,
      }
    }
    return serverSideObject
  },
  toClientSideObjectsForHistory(serverSideObjects) {
    let clientSideObjects = []
    if (serverSideObjects) {
      for(let serverSideObject of serverSideObjects) {
        let clientSideObject = {
          id: serverSideObject.historyId,
          date: moment(serverSideObject.baseDate),
          daytype: serverSideObject.holidayType,
          starttime: serverSideObject.punchIn ? moment(serverSideObject.punchIn) : undefined,
          endtime: serverSideObject.punchOut ? moment(serverSideObject.punchOut) : undefined,
          breaktime: serverSideObject.breakTime,
          remarks: serverSideObject.remarks,
        }
        clientSideObject.totaltime = this.getTotalTime(clientSideObject.starttime, clientSideObject.endtime, clientSideObject.breaktime)
        clientSideObjects.push(clientSideObject)
      }
    }
    return clientSideObjects
  },
  getTotalTime(starttime, endtime, breaktime) {
    let totaltime = 0
    if (starttime && endtime) {
      totaltime = this.getDiffHour(starttime, endtime)
      totaltime = breaktime ? totaltime - breaktime : totaltime
      totaltime = totaltime < 0 ? 0 : totaltime
    }
    return totaltime
  },
  getDiffHour(starttime, endtime) {
    let diffHour = 0
    if (starttime && endtime) {
      diffHour = endtime.diff(starttime, 'minutes') / 60
    }
    return diffHour
  },
}