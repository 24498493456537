<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          送信テンプレート設定
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12">
                <v-select
                  v-model="mailto"
                  :items="getAddressList()"
                  item-text="name"
                  item-value="address"
                  label="宛先"
                  multiple
                  chips
                  prepend-icon="mdi-email-outline"
                  small-chips
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12">
                <v-textarea
                  v-model="comment"
                  label="コメント"
                  rows="5"
                  outlined>
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="common.color.primary"
            text
            @click="isOpen = false"
          >
            キャンセル
          </v-btn>
          <v-btn
            :color="common.color.primary"
            text
            @click="save()"
          >
            設定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      isOpen: false,
      mailto: null,
      comment: null,
    }),
    methods: {
      initialize() {
        var mailtoStr = this.getSettingsValue(this.common.settings.submit.mailto)
        this.mailto = mailtoStr ? mailtoStr.split(',') : undefined
        this.comment = this.getSettingsValue(this.common.settings.submit.comment)
        this.common.eventBus.$emit('App.overflow.remove')
        this.isOpen = true
      },
      save() {
        var settings = []
        var mailtoObj = this.getNewSettingsObject(this.common.settings.submit.mailto, this.mailto ? this.mailto.join(','): undefined)
        var commentObj = this.getNewSettingsObject(this.common.settings.submit.comment, this.comment)
        if (mailtoObj) {
          settings.push(mailtoObj)
        } else {
          this.deleteSettings(this.common.settings.submit.mailto)
        }
        if (commentObj) {
          settings.push(commentObj)
        } else {
          this.deleteSettings(this.common.settings.submit.comment)
        }
        this.setSettings(settings)
        .then(() => {
          this.isOpen = false
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
    },
    created() {
      this.common.eventBus.$on('SubmitSettingDialog.open', this.initialize)
    },
}
</script>

<style scoped>
</style>
