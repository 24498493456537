import abstractApi from './abstract-api'

let API_URL = "/settings-api"

let api = {
      find: API_URL + "/find",
      add: API_URL + "/add",
      edit: API_URL + "/edit",
      delete: API_URL + "/delete",
    }

export default{
  get(token) {
    return abstractApi.executeApi(api.find, undefined, token)
  },
  set(clientSideObject, isNew, token) {
    return abstractApi.executeApi(isNew ? api.add : api.edit , clientSideObject, token)
  },
  delete(key, token) {
    return abstractApi.executeApi(api.delete, {key: String(key)}, token)
  },
}