<template>
  <div class="commuter-pass-list-items ml-1" 
    v-if="
      isShow &&
      displayItem && 
      displayItem.commuterPass && 
      displayItem.commuterPass.length">
    <v-list-item
      class="commuter-pass-list-items__item mb-1 mt-1"
      v-for="(commuterPass, index) in displayItem.commuterPass"
      :key="'commuter-pass-' + index"
      three-line
      @click="openCommuterPassDialog(index)"
      >
      <v-list-item-content class="commuter-pass-list-items__item__content">
        <v-list-item-subtitle >
          {{commuterPass.discription}}
        </v-list-item-subtitle>
        <v-list-item-title class="commuter-pass-list-items__item__title mt-1">
          <span>{{commuterPass.startSection}}</span>
          <v-icon small class="ml-1 mr-1">mdi-arrow-left-right</v-icon>
          <span>{{commuterPass.endSection}}</span>
          <span class="commuter-pass-list-items__item__transportation">
            （{{commuterPass.transportation}}）
          </span>
        </v-list-item-title>
        <v-list-item-subtitle class="mt-1">
          {{commuterPass.amount.toLocaleString()}}<span class="ml-1">円</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action style="align-self: center;">
        <v-btn icon @click.stop="remove(commuterPass.id)">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>        
</template>

<script>

  export default {
    props: ["common", "data", "dialog", "displayItem"],
    data: () => ({
      isShow: true,
    }),
    methods: {
      openCommuterPassDialog(index) {
        this.dialog.year = this.common.select.year
        this.dialog.month = this.common.select.month
        this.dialog.commuterPass = this.displayItem.commuterPass[index]
        this.common.eventBus.$emit('CommuterPassDialog.open')
      },
      remove(id) {
        this.deleteCommuterPass(id)
        .then(() => {
          this.isOpen = false
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
      reload() {
        this.isShow = false
        this.isShow = true
      },
    },
    created() {
      this.common.eventBus.$on('CommuterPassItems.reload', this.reload)
    },
}
</script>

<style scoped>
  .commuter-pass-list-items {
    text-align: left;
  }
  .commuter-pass-list-items__item {
    border-left: 5px solid var(--commuterpassColor);
  }
  .commuter-pass-list-items__item__content {
    color: var(--textColor-default)
  }
  .commuter-pass-list-items__item__title {
    display: flex;
    align-items: center;
  }
  .commuter-pass-list-items__item__transportation {
    font-size: small;
  }
</style>
