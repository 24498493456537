<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      scrollable
      max-width="600px"
    >
      <v-form ref="standardWorktimeDialogForm">
        <v-card>
          <v-card-title>
            標準時間設定
            <v-spacer></v-spacer>
            <v-icon right @click="remove()">
              mdi-trash-can-outline
            </v-icon>
          </v-card-title>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-dialog
                    ref="starttimeDialog"
                    v-model="starttimeDialog"
                    :return-value.sync="starttime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="starttime"
                        label="開始時間"
                        prepend-icon="mdi-clock-time-nine-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="starttimeDialog"
                      v-model="starttime"
                      full-width
                      format="24hr"
                      :allowed-minutes="allowedStep"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="starttimeDialog = false"
                      >
                        キャンセル
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.starttimeDialog.save(starttime);changedStartEndTime()"
                      >
                        設定
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-dialog
                    ref="endtimeDialog"
                    v-model="endtimeDialog"
                    :return-value.sync="endtime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endtime"
                        label="終了時間"
                        prepend-icon="mdi-clock-time-six-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="endtimeDialog"
                      v-model="endtime"
                      full-width
                      format="24hr"
                      :allowed-minutes="allowedStep"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="endtimeDialog = false"
                      >
                        キャンセル
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endtimeDialog.save(endtime);changedStartEndTime()"
                      >
                        設定
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="breaktime"
                    :items="breaktimeList"
                    v-if="breaktimeList"
                    label="休憩時間"
                    prepend-icon="mdi-coffee-outline"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div style="display: flex; justify-content: flex-end;">
                    <v-switch
                      v-model="isAutoSet"
                      label="毎月自動設定"
                      inset
                      dense
                    ></v-switch>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="common.color.primary"
              text
              @click="isOpen = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              :color="common.color.primary"
              text
              @click="save()"
            >
              設定
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      isOpen: false,
      starttime: null,
      endtime: null,
      breaktime: null,
      isAutoSet: false,
      starttimeDialog: false,
      endtimeDialog: false,
      breaktimeList: [0],
      rules: [
        value => !!value || 'Required.',
      ],      
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove')
        if (this.$refs.standardWorktimeDialogForm) {
          this.$refs.standardWorktimeDialogForm.resetValidation()
        }
        this.starttime = this.getSettingsValue(this.common.settings.standardWorktime.starttime)
        this.endtime = this.getSettingsValue(this.common.settings.standardWorktime.endtime)
        this.breaktimeList = this.getBreakTimeList(this.starttime, this.endtime)
        var breaktimeVal = this.getSettingsValue(this.common.settings.standardWorktime.breaktime)
        this.breaktime = breaktimeVal ? Number(breaktimeVal) : undefined
        this.isAutoSet = this.getSettingsValue(this.common.settings.standardWorktime.isAutoSet) == 'true'
        this.isOpen = true
      },
      save() {
        let isSuccess = this.$refs.standardWorktimeDialogForm.validate()
        if (isSuccess) {
          var settings = []
          var starttimeObj = this.getNewSettingsObject(
              this.common.settings.standardWorktime.starttime,
              this.starttime)
          settings.push(starttimeObj)
          var endtimeObj = this.getNewSettingsObject(
              this.common.settings.standardWorktime.endtime,
              this.endtime)
          settings.push(endtimeObj)
          var breaktimeObj = this.getNewSettingsObject(
              this.common.settings.standardWorktime.breaktime,
              String(this.breaktime))
          settings.push(breaktimeObj)
          var isAutoSetObj = this.getNewSettingsObject(
              this.common.settings.standardWorktime.isAutoSet,
              String(this.isAutoSet))
          settings.push(isAutoSetObj)
          this.setSettings(settings)
          .then(() => {
            this.isOpen = false
            this.common.eventBus.$emit('App.reload')
            if(this.isAutoSet){
              this.confirmAutoSet()
            }
          })
          .catch(err => {
            this.errorProcess(err)
          })
        }
      },
      remove() {
        new Promise((resolve) => {
          let promise = []
          promise.push(this.deleteSettings(this.common.settings.standardWorktime.starttime))
          promise.push(this.deleteSettings(this.common.settings.standardWorktime.endtime))
          promise.push(this.deleteSettings(this.common.settings.standardWorktime.breaktime))
          promise.push(this.deleteSettings(this.common.settings.standardWorktime.isAutoSet))
          Promise.all(promise)
          .then(() => {
            this.isOpen = false
            this.common.eventBus.$emit('App.reload')
            resolve()
          })
          .catch(err => {
            this.errorProcess(err)
          })
        })
      },
      changedStartEndTime(){
        this.breaktimeList = this.getBreakTimeList(this.starttime, this.endtime)
      },
      confirmAutoSet(){
        this.dialog.confirm = {
          title: "毎月自動設定についての確認",
          message: 
            "毎月自動設定が ON になっています。\n " + 
            this.common.select.year + "年" + this.common.select.month + "月の勤務時間を登録しますか？\n " +
            "※既に登録済みの勤務時間も更新されます\n " + 
            " <設定された標準時間>\n "+
            "  開始時間：" + this.starttime + "\n " +
            "  終了時間：" + this.endtime + "\n " +
            "  休憩時間：" + this.breaktime,
          ok: "はい",
          ng: "いいえ",
          okAction: function(){
            this.setStandardWorkTimeForCurrentMonth()
          },
          ngAction: function(){
          }
        }
        this.common.eventBus.$emit('ConfirmDialog.open')
      },
    },
    created() {
      this.common.eventBus.$on('StandardWorkTimeDialog.open', this.initialize)
    },
}
</script>

<style scoped>
</style>
