<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="600px"
      scrollable
    >
      <v-form ref="expensesDialogForm">
        <v-card>
          <v-card-title>
            <span class="expenses-dialog__title">
              <span class="text-h5">{{year}}</span>年
              <span class="text-h5">{{month}}</span>月
              <span class="text-h5">{{day}}</span>日
              ({{common.week[common.select.dayOfWeek]}})
            </span>
            <v-spacer></v-spacer>
            <v-icon right @click="remove()">
              mdi-trash-can-outline
            </v-icon>
          </v-card-title>
          <v-card-subtitle class="mt-1 pb-2">
            仮払経費
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4">
                  <v-text-field
                    v-model="accountTitle"
                    label="勘定科目"
                    prepend-icon="mdi-clipboard-text-outline"
                    :rules="rules"
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12">
                  <v-textarea
                    v-model="discription"
                    label="摘要"
                    prepend-icon="mdi-text-box-multiple-outline"
                    rows="3"
                    placeholder="打合せ  ３名  ○○○居酒屋"
                    :rules="rules"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="amount"
                    label="金額"
                    prepend-inner-icon="mdi-currency-jpy"
                    type="number"
                    outlined
                    clearable>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="common.color.primary"
              text
              @click="isOpen = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              :color="common.color.primary"
              text
              @click="save()"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      isOpen: false,
      year: null,
      month: null,
      day: null,
      accountTitle: null,
      discription: null,
      amount: null,
      rules: [
        value => !!value || 'Required.',
      ],      
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove')
        if (this.$refs.expensesDialogForm) {
          this.$refs.expensesDialogForm.resetValidation()
        }
        this.year = this.dialog.year
        this.month = this.dialog.month
        this.day = this.dialog.day
        this.id = this.dialog.expense.id
        this.accountTitle = this.dialog.expense.accountTitle
        this.discription = this.dialog.expense.discription
        this.amount = this.dialog.expense.amount
        this.isOpen = true
      },
      save() {
        let isSuccess = this.$refs.expensesDialogForm.validate()
        if (isSuccess) {
          let date = this.getCurrentDate()
          let expense = {
            id: this.id,
            date: date,
            accountTitle: this.accountTitle,
            discription: this.discription,
            amount: this.amount ? Number(this.amount) : 0,
          }
          new Promise((resolve) => {
            let promise = []
            promise.push(this.setExpense(expense))
            promise.push(this.setExpensesHistory(expense))
            let sameExpense = this.getSameExpensesHistory(expense)
            if (sameExpense) {
              promise.push(this.deleteExpensesHistory(sameExpense.id))
            }
            Promise.all(promise)
            .then(() => {
              this.isOpen = false
              this.common.select.day = undefined
              this.common.select.day = this.day
              this.common.eventBus.$emit('App.reload')
              resolve()
            })
            .catch(err => {
              this.errorProcess(err)
            })
          })

        }
      },
      remove() {
        this.deleteExpense(this.id)
        .then(() => {
          this.isOpen = false
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
      getCurrentDate() {
        return moment(new Date(this.year, this.month - 1, this.day))
      },
    },
    created() {
      this.common.eventBus.$on('ExpensesDialog.open', this.initialize)
    },
}
</script>

<style scoped>
  .expenses-dialog__title {
    font-size: medium;
  }
</style>
