<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="600px"
      scrollable
    >
      <v-form ref="commuterPassForm">
        <v-card>
          <v-card-title>
            <span class="commuter-pass-dialog__title">
              <span class="text-h5">{{year}}</span>年
              <span class="text-h5">{{month}}</span>月
            </span>
            <v-spacer></v-spacer>
            <v-icon right @click="remove()">
              mdi-trash-can-outline
            </v-icon>
          </v-card-title>
          <v-card-subtitle class="mt-1 pb-2">
            定期券
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4">
                  <v-text-field
                    v-model="transportation"
                    label="交通手段"
                    prepend-icon="mdi-train-car"
                    :rules="rules"
                    dense
                    >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4">
                  <v-text-field
                    v-model="startSection"
                    label="出発地"
                    prepend-icon="mdi-alpha-s-box-outline"
                    :rules="rules"
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12"
                  sm="6"
                  md="4">
                  <v-text-field
                    v-model="endSection"
                    label="目的地"
                    prepend-icon="mdi-alpha-g-box-outline"
                    :rules="rules"
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="amount"
                    label="料金"
                    prepend-inner-icon="mdi-currency-jpy"
                    type="number"
                    dense
                    outlined
                    clearable>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="common.color.primary"
              text
              @click="isOpen = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              :color="common.color.primary"
              text
              @click="save()"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      isOpen: false,
      year: null,
      month: null,
      id: null,
      accountTitle: "定期券",
      transportation: null,
      startSection: null,
      endSection: null,
      amount: null,
      rules: [
        value => !!value || 'Required.',
      ],      
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove')
        if (this.$refs.commuterPassForm) {
          this.$refs.commuterPassForm.resetValidation()
        }
        this.year = this.dialog.year
        this.month = this.dialog.month
        this.id = this.dialog.commuterPass.id
        this.transportation = this.dialog.commuterPass.transportation
        this.startSection = this.dialog.commuterPass.startSection
        this.endSection = this.dialog.commuterPass.endSection
        this.amount = this.dialog.commuterPass.amount
        this.isOpen = true
      },
      save() {
        let isSuccess = this.$refs.commuterPassForm.validate()
        if (isSuccess) {
          let date = this.getCurrentDate()
          let commuterPass = {
            id: this.id,
            date: date,
            accountTitle: this.accountTitle,
            transportation: this.transportation,
            startSection: this.startSection,
            endSection: this.endSection,
            amount: this.amount ? Number(this.amount) : 0,
          }
          this.setCommuterPass(commuterPass)
          .then(() => {
            this.isOpen = false
            var day = this.common.select.day
            this.common.select.day = undefined
            this.common.select.day = day
            this.common.eventBus.$emit('App.reload')
          })
          .catch(err => {
            this.errorProcess(err)
          })
        }
      },
      remove() {
        this.deleteCommuterPass(this.id)
        .then(() => {
          this.isOpen = false
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
      getCurrentDate() {
        return moment(new Date(this.year, this.month - 1, 1))
      },
    },
    created() {
      this.common.eventBus.$on('CommuterPassDialog.open', this.initialize)
    },
}
</script>

<style scoped>
  .commuter-pass-dialog__title {
    font-size: medium;
  }
</style>
