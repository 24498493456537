<template>
  <v-app-bar class="plus-buttons" bottom app> 
    <div style="flex-grow: 1">
      <WorkTimeBottomSheet :common="common" :data="data" :dialog="dialog"/>
    </div>
    <div class="pl-2 pr-2" style="flex-grow: 1">
      <TravelsBottomSheet :common="common" :data="data" :dialog="dialog"/>
    </div>
    <div style="flex-grow: 1">
      <ExpensesBottomSheet :common="common" :data="data" :dialog="dialog"/>
    </div>
  </v-app-bar>
</template>

<script>
  import WorkTimeBottomSheet from './WorkTimeBottomSheet.vue';
  import TravelsBottomSheet from './TravelsBottomSheet.vue';
  import ExpensesBottomSheet from './ExpensesBottomSheet.vue';

  export default {
    props: ["common", "data", "dialog"],
    components: {
      WorkTimeBottomSheet,
      TravelsBottomSheet,
      ExpensesBottomSheet,
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>
