<template>
  <v-app-bar :color="common.color.secondary" height="50px" fixed style="top:50px;" v-if="isShow" dense>
    <div class="contents-header">
      <div>
        <v-btn 
          small
          left
          class="viewmode-button"
          :class="viewmodebutton.class" 
          @click="toggleViewMode"
          :color="common.color.primary"
          depressed>
          <v-icon left :color="viewmodebutton.first.color">mdi-dots-grid</v-icon>
          <v-icon right :color="viewmodebutton.second.color">mdi-format-list-bulleted</v-icon>
        </v-btn>
      </div>
      <div>
        <v-dialog
          ref="dialog"
          v-model="monthPicker"
          max-width="600px"
          >
          <template v-slot:activator="{ on, attrs }">
            <span class="contents-header__title"
              v-bind="attrs"
              v-on="on">
              {{common.select.year}}年
              <span class="contents-header__title__month ml-1 mr-1">{{common.select.month}}</span>
              月
            </span>
          </template>
          <v-date-picker
            v-model="month"
            no-title
            type="month"
            width="290"
            locale="ja-JP"
            @change="chengeMonth"/>
        </v-dialog>
      </div>
      <div>
        <v-chip
          :color="common.color.secondary"
          style="border: 1px solid var(--primaryColor); font-weight: bold"
          :text-color="common.color.primary"
          small
          @click="openCommuterPass"
        >
          定期
          <v-avatar
            left
            :color="common.color.primary"
            style="height: 15px !important; width: 15px !important; min-width: 0 !important;"
            right
          >
            <span style="font-size: x-small; color: white">{{commuterPassCount}}</span>
          </v-avatar>
        </v-chip>
        <v-icon 
          class="ml-4"
          :color="common.color.primary" 
          @click="openMonthlyStatistics">
          mdi-information
        </v-icon>
      </div>
    </div>
  </v-app-bar>
</template>

<script>

  export default {
    props: ["common", "data"],
    data: () => ({
      isShow: true,
      monthPicker: false,
      viewmodebutton: {
        class: "viewmode-button--active-1st",
        first: {
          color: "white"
        },
        second: {
          color: ""
        }
      },
      commuterPassCount: 0,
    }),
    methods: {
      reload() {
        this.commuterPassCount = this.data.commuterPass.thisMonth.length
        this.isShow = false
        this.isShow = true
      },
      chengeMonth() {
        let year = parseInt(this.month.split('-')[0],10)
        let month = parseInt(this.month.split('-')[1],10)
        this.setSelectDate(year, month, this.common.select.day)
        this.common.eventBus.$emit('App.reload')
        this.month = this.common.select.date.format("YYYY-MM")
        this.monthPicker = false
      },
      toggleViewMode() {
        this.common.viewmode.isMonthly= !this.common.viewmode.isMonthly
        this.viewmodebutton.first.color= this.common.viewmode.isMonthly ? "white" : ""
        this.viewmodebutton.second.color= !this.common.viewmode.isMonthly ? "white" : ""
        this.viewmodebutton.class= this.common.viewmode.isMonthly ? "viewmode-button--active-1st" : "viewmode-button--active-2nd"
      },
      openCommuterPass() {
        this.common.eventBus.$emit('CommuterPassList.open')
      },
      openMonthlyStatistics() {
        this.common.eventBus.$emit('MonthlyStatistics.open')
      },
    },
    created() {
      this.month = this.common.select.date.format("YYYY-MM")
      this.commuterPassCount = this.data.commuterPass.thisMonth.length
      this.common.eventBus.$on('ContentsHeader.reload', this.reload);
    }
  }
</script>

<style scoped>
  .contents-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;    
  }
  .contents-header__title {
    color: var(--textColor-default);
    font-size: small;
  }
  .contents-header__title__month {
    font-size: x-large;
  }
  .viewmode-button {
    border:2px solid var(--primaryColor);
  }
  .viewmode-button--active-1st {
    background:linear-gradient(90deg,var(--primaryColor) 0%,var(--primaryColor) 50%,var(--secondaryColor) 50%,var(--secondaryColor) 100%);
  }
  .viewmode-button--active-2nd {
    background:linear-gradient(90deg,var(--secondaryColor) 0%,var(--secondaryColor) 50%,var(--primaryColor) 50%,var(--primaryColor) 100%);
  }
</style>
