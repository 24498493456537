import axios from 'axios'

let API_URL = 'https://api.national-holidays.jp/'

export default{
  get(param) {
    return axios.get(API_URL + param)
  },
  toHolidays(holidayList) {
    let holidays = {}
    if (holidayList) {
      for(let holiday of holidayList) {
        holidays[holiday.date] = holiday.name
      }
    }
    return holidays
  },
}