import moment from 'moment'
import abstractApi from './abstract-api'

let API_URL = "/pass-price-api"

let api = {
      find: API_URL + "/find",
      add: API_URL + "/add",
      edit: API_URL + "/edit",
      delete: API_URL + "/delete",
      deleteByMonth: API_URL + "/delete/month",
    }

export default{
  get(month, token) {
    return abstractApi.executeApi(api.find, {baseMonth: month}, token)
  },
  set(clientSideObject, token) {
    let serverSideObject = this.toServerSideObject(clientSideObject)
    return abstractApi.executeApi(clientSideObject.id ? api.edit : api.add , serverSideObject, token)
  },
  delete(id, token) {
    return abstractApi.executeApi(api.delete, {passPriceId: String(id)}, token)
  },
  toClientSideObjects(serverSideObjects) {
    let clientSideObjects = []
    if (serverSideObjects) {
      for(let serverSideObject of serverSideObjects) {
        let clientSideObject = {
          id: serverSideObject.passPriceId,
          date: moment(serverSideObject.baseDate),
          transportation: serverSideObject.transportation,
          startSection: serverSideObject.startSection,
          endSection: serverSideObject.endSection,
          amount: serverSideObject.amount ? Number(serverSideObject.amount) : 0,
        }
        clientSideObjects.push(clientSideObject)
      }
    }
    return clientSideObjects
  },
  toServerSideObjects(clientSideObjects) {
    let serverSideObjects = []
    if (clientSideObjects) {
      for(let clientSideObject of clientSideObjects) {
        let serverSideObject = this.toServerSideObject(clientSideObject)
        serverSideObjects.push(serverSideObject)
      }
    }
    return serverSideObjects
  },
  toServerSideObject(clientSideObject) {
    let serverSideObject = {}
    if (clientSideObject) {
      serverSideObject = {
        passPriceId: clientSideObject.id ? String(clientSideObject.id) : undefined,
        accountTitle: clientSideObject.accountTitle,
        baseMonth: clientSideObject.date.format("YYYY-MM"),
        transportation: clientSideObject.transportation,
        startSection: clientSideObject.startSection,
        endSection: clientSideObject.endSection,
        amount: clientSideObject.amount ? String(clientSideObject.amount) : "0",
      }
    }
    return serverSideObject
  },
}