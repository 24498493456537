import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import common from './js/common';
import VueCookies from 'vue-cookies'
import router from './router.js'
import axios from 'axios'

Vue.config.productionTip = false
Vue.mixin(common)
Vue.use(VueCookies)
var VueTouch = require('vue-touch')
Vue.use(VueTouch, {name: 'v-touch'})
Vue.use({
  install (Vue) {
    Vue.prototype.$axios = axios.create({
      baseURL: process.env.BASE_URL
    })
  }
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')