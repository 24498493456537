<template>
  <v-navigation-drawer
    v-model="isOpen"
    app
    temporary
  >
    <v-card height="100%" class="sidemenu">
      <v-card-title class="sidemenu__title mt-1">
        <span>{{data.user.lastName}}</span>
        <span class="ml-1">{{data.user.firstName}}</span>
      </v-card-title>
      <v-card-subtitle>
        {{data.user.email}}
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="sidemenu__list">
        <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="group"
            active-class="sidemenu__list__item--active"
          >
            <v-list-item>
              <v-list-item-title 
                class="sidemenu__list__item__title"
                @click="openStandardWorkTimeDialog">標準時間設定</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title 
                class="sidemenu__list__item__title"
                @click="openSubmitSettingDialog">送信テンプレート設定</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="sidemenu__list__item__title"
              @click="openSubmitHistory">提出履歴</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="sidemenu__list__item__title"
              @click="openHowToUse">使い方</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-btn
          color="blue-grey darken-1"
          dark
          width="100%"
          @click="logout(); isOpen = false">
          ログアウト
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
  export default {
    props: ["common", "data"],
    data: () => ({
      isOpen: false,
      group: null,
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove')
        this.common.eventBus.$emit('WorkTimeBottomSheet.close')
        this.common.eventBus.$emit('TravelsBottomSheet.close')
        this.common.eventBus.$emit('ExpensesBottomSheet.close')
        this.isOpen = true
      },
      openStandardWorkTimeDialog() {
        this.isOpen = false
        this.common.eventBus.$emit('StandardWorkTimeDialog.open')
      },
      openSubmitSettingDialog() {
        this.isOpen = false
        this.common.eventBus.$emit('SubmitSettingDialog.open')
      },
      openSubmitHistory() {
        this.isOpen = false
        this.common.eventBus.$emit('SubmitHistory.open')
      },
      openHowToUse() {
        this.isOpen = false
        this.common.eventBus.$emit('HowToUse.open')
      }
    },
    created() {
      this.common.eventBus.$on('SideMenu.open', this.initialize)
    },
  }

</script>

<style scoped>
  .sidemenu {
    display: flex;
    flex-direction: column;
  }
  .sidemenu__list__item--active {
    color: var(--primaryColor) !important;
    caret-color: var(--primaryColor) !important;
  }
  .sidemenu__list__item__title {
    color: var(--textColor-default);
  }
</style>
