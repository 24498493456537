<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      persistent
      scrollable
      max-width="600px"
    >
      <v-form ref="confirmDialogForm">
        <v-card>
          <v-card-title style="font-size: medium;" class="pt-3 pb-3" v-if="title">
            {{title}}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span style="white-space: pre-wrap;">
              {{message}}
            </span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="common.color.primary"
              text
              @click="ngAction(); isOpen=false"
              v-if="ng"
            >
              {{ng}}
            </v-btn>
            <v-btn
              :color="common.color.primary"
              text
              @click="okAction(); isOpen=false"
              v-if="ok"
            >
              {{ok}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      isOpen: false,
      title: undefined,
      message: undefined,
      ok: undefined,
      ng: undefined,
      okAction: undefined,
      ngAction: undefined,
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove')
        if(this.dialog.confirm){
          this.title = this.dialog.confirm.title
          this.message = this.dialog.confirm.message
          this.ok = this.dialog.confirm.ok
          this.ng = this.dialog.confirm.ng
          this.okAction = this.dialog.confirm.okAction
          this.ngAction = this.dialog.confirm.ngAction
        }
        this.isOpen = true
      },
    },
    created() {
      this.common.eventBus.$on('ConfirmDialog.open', this.initialize)
    },
}
</script>

<style scoped>
</style>
