import axios from 'axios'
import moment from 'moment'

let API_URL=process.env.VUE_APP_API_BASE_URL

export default{
  data: () => ({
  }),
  executeApi(url, param, token) {
    param = param || {}
    param.requestDatetime = moment().format("YYYY-MM-DD HH:mm:ss.SSS") + '_' + String(this.random(0, 9999999))
    return axios.post(API_URL + url + (token ? "?token=" + token : ''), param)
  },
  random(min, max) {
    return Math.floor( Math.random() * (max + 1 - min) ) + min
  }
}