<template>
  <form class="mt-16">
    <v-container class="text-center" style="display: block; max-width:500px">
      <v-text-field
        v-model="userId"
        label="ユーザーID"
        :rules="rules"
      ></v-text-field>
      <v-text-field
        v-model="password"
        label="パスワード"
        type="password"
        :rules="rules"
      ></v-text-field>
      <v-btn
        class="mt-5"
        @click="userLogin"
        dark
        width="100%"
        max-width="500px"
        :color="common.color.primary"
      >
        ログイン
      </v-btn>
    </v-container>
    <ConfirmDialog :common="common" :dialog="dialog"/>
  </form>
</template>

<script>
  import ConfirmDialog from './ConfirmDialog.vue'

  export default {
  components: {
    ConfirmDialog,
  },
  props: ["common"],
    data: () => ({
      userId: null,
      password: null,
      dialog: {},
      rules: [
        value => !!value || 'Required.',
      ],      
    }),
    methods: {
      userLogin() {
        if (this.userId && this.password) {
          this.login(this.userId, this.password)
          .then(response => {
            if (response.data) {
              this.common.account.token = response.data.token
              this.setCookie(this.common.cookie.token, this.common.account.token)
              this.$router.push('/')
            }
          })
          .catch(err => {
            this.errorProcess(err)
          })
        }
      },
    },
    created() {
      console.log("login")
      if (this.getCookie(this.common.cookie.token)) {
        this.$router.push('/')
      }
    },
}
</script>

<style scoped>
</style>
