import abstractApi from './abstract-api'

let API_URL_USER = '/user-api'
let API_URL_EMPLOYEE = '/employee-api'
let API_URL_LOGIN = '/login'

let api = {
      user: {
        find: API_URL_USER + "/find",
      },
      employee: {
        find: API_URL_EMPLOYEE + "/find",
      },
      login:  API_URL_LOGIN,
    }

export default{
  getUser(token) {
    return abstractApi.executeApi(api.user.find, undefined, token)
  },
  getEmployee(token) {
    return abstractApi.executeApi(api.employee.find, undefined, token)
  },
  login(id, password) {
    return abstractApi.executeApi(api.login, {username: id, password: password})
  },
}