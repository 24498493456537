import moment from 'moment'
import abstractApi from './abstract-api'

let API_URL = "/traveling-expenses-api"
let HISTORY_API_URL = "/traveling-expenses-history-api"

let api = {
      find: API_URL + "/find",
      section: API_URL + "/find/section",
      add: API_URL + "/add",
      edit: API_URL + "/edit",
      delete: API_URL + "/delete",
      deleteByMonth: API_URL + "/delete/month",
      excel: API_URL + "/excel",
      download: API_URL + "/download",
      history: {
        find: HISTORY_API_URL + "/find",
        add: HISTORY_API_URL + "/add",
        delete: HISTORY_API_URL + "/delete",
      }
    }

export default{
  get(month, token) {
    return abstractApi.executeApi(api.find, {month: month}, token)
  },
  set(clientSideObject, token) {
    let serverSideObject = this.toServerSideObject(clientSideObject)
    return abstractApi.executeApi(clientSideObject.id ? api.edit : api.add , serverSideObject, token)
  },
  delete(id, token) {
    return abstractApi.executeApi(api.delete, {travelingExpenseId: String(id)}, token)
  },
  excel(month, token) {
    return abstractApi.executeApi(api.excel, {month: month}, token)
  },
  setHistory(clientSideObject, token) {
    let serverSideObject = this.toServerSideObject(clientSideObject)
    return abstractApi.executeApi(api.history.add, serverSideObject, token)
  },
  getHistory(token) {
    return abstractApi.executeApi(api.history.find, undefined, token)
  },
  deleteHistory(id, token) {
    return abstractApi.executeApi(api.history.delete, {travelingExpenseId: String(id)}, token)
  },
  toClientSideObjects(serverSideObjects) {
    let clientSideObjects = []
    if (serverSideObjects) {
      for(let serverSideObject of serverSideObjects) {
        let clientSideObject = {
          id: serverSideObject.travelingExpenseId,
          date: moment(serverSideObject.baseDate),
          accountTitle: serverSideObject.accountTitle,
          discription: serverSideObject.discription,
          transportation: serverSideObject.transportation,
          startSection: serverSideObject.startSection,
          endSection: serverSideObject.endSection,
          sectionType: serverSideObject.sectionType,
          amount: serverSideObject.amount ? Number(serverSideObject.amount) : 0,
        }
        clientSideObjects.push(clientSideObject)
      }
    }
    return clientSideObjects
  },
  toServerSideObjects(clientSideObjects) {
    let serverSideObjects = []
    if (clientSideObjects) {
      for(let clientSideObject of clientSideObjects) {
        let serverSideObject = this.toServerSideObject(clientSideObject)
        serverSideObjects.push(serverSideObject)
      }
    }
    return serverSideObjects
  },
  toServerSideObject(clientSideObject) {
    let serverSideObject = {}
    if (clientSideObject) {
      serverSideObject = {
        travelingExpenseId: clientSideObject.id ? String(clientSideObject.id) : undefined,
        accountTitle: clientSideObject.accountTitle,
        baseDate: clientSideObject.date.format("YYYY-MM-DD"),
        discription: clientSideObject.discription,
        transportation: clientSideObject.transportation,
        startSection: clientSideObject.startSection,
        endSection: clientSideObject.endSection,
        sectionType: clientSideObject.sectionType,
        amount: clientSideObject.amount ? String(clientSideObject.amount) : "0",
      }
    }
    return serverSideObject
  },
}