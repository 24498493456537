<template>
  <v-bottom-sheet
    v-model="worktimeBottomSheet"
    inset
    scrollable
    hide-overlay
    no-click-animation
    persistent
    internal-activator
  >
    <template v-slot:activator="{ attrs }">
      <v-btn
        :color="common.color.worktime" block small dark
        v-bind="attrs"
        @click="openBottomSheet">
        <v-icon dark small left>
          mdi-plus
        </v-icon>
        勤務時間
      </v-btn>
    </template>
    <v-card v-if="isShow" class="worktime-bottom-sheet" >
      <v-card-title class="worktime-bottom-sheet__title">
        <span class="worktime-bottom-sheet__title__date">
          <span class="worktime-bottom-sheet__title__date__num" >{{common.select.year}}</span>年
          <span class="worktime-bottom-sheet__title__date__num" >{{common.select.month}}</span>月
          <span class="worktime-bottom-sheet__title__date__num" >{{common.select.day}}</span>日
          (<span class="ml-1 mr-1">{{common.week[common.select.dayOfWeek]}}</span>)
        </span>
        <v-spacer/>
        <v-icon @click="closeBottomSheet">
          mdi-chevron-down
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="worktime-bottom-sheet__content">
        <v-list dense>
          <v-subheader>履歴から設定</v-subheader>
          <v-list-item-group
            class="worktime-bottom-sheet__list"
            :color="common.color.primary">          
            <v-list-item
              v-for="(worktime, key) in data.history.worktimes"
              :key="key">
              <v-list-item-content 
                v-if="worktime.daytype == 0"
                @click="insertWorkTime(worktime)">
                <v-list-item-subtitle >{{worktime.remarks}}</v-list-item-subtitle>
                <v-list-item-title class="worktime-bottom-sheet__list-item__title">
                  <span>{{worktime.starttime.format("H:mm")}}</span>
                  <span class="ma-1">〜</span>
                  <span>{{worktime.endtime.format("H:mm")}}</span>
                  <span class="worktime-bottom-sheet__list-item__breaktime">
                    （休憩：<span class="mr-1">{{worktime.breaktime}}</span>h）</span>
                </v-list-item-title>
                <v-list-item-subtitle >{{worktime.totaltime}}<span class="ml-1">h</span></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="worktime-list-items__item__content"
                v-else
                @click="insertWorkTime(worktime)">
                <v-list-item-title 
                  :class="{
                    'worktime-bottom-sheet__list-item__title--holiday': worktime.daytype == 1,
                    'worktime-bottom-sheet__list-item__title--vacation': worktime.daytype == 2}">
                  <span>
                    {{common.daytype[worktime.daytype]}}
                    <span v-if="worktime.remarks">（{{worktime.remarks}}）</span>
                  </span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon small @click="removeHistoryItem(worktime)">
                  mdi-close
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-5">
        <v-spacer/>
        <v-btn :color="common.color.worktime" dark block
          @click="openWorkTimeDialog">
          <v-icon dark small left>
            mdi-plus
          </v-icon>
          新たに勤務時間を設定する
        </v-btn>
        <v-spacer/>
      </v-card-actions>        
    </v-card>
  </v-bottom-sheet>
</template>

<script>
  import moment from 'moment'

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      worktimeBottomSheet: false,
      isShow: true,
    }),
    methods: {
      openBottomSheet() {
        this.common.eventBus.$emit('App.overflow.add')
        this.worktimeBottomSheet = true
      },
      closeBottomSheet() {
        this.common.eventBus.$emit('App.overflow.remove')
        this.worktimeBottomSheet = false
      },
      openWorkTimeDialog() {
        this.dialog.year = this.common.select.year
        this.dialog.month = this.common.select.month
        this.dialog.day = this.common.select.day
        this.dialog.worktime = this.data.worktimes[this.common.select.date.format("D")] || {}
        this.common.eventBus.$emit('WorkTimeDialog.open')
      },
      removeHistoryItem(worktime) {
        this.deleteWorkTimesHistory(worktime.id)
        .then(() => {
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
      insertWorkTime(worktime) {
        let temp = { ...worktime }
        temp.date = this.common.select.date.clone()
        temp.starttime = moment(temp.date.format("YYYY-MM-DD") + ' ' + worktime.starttime.format("HH:mm:ss"))
        temp.endtime = moment(temp.date.format("YYYY-MM-DD") + ' ' + worktime.endtime.format("HH:mm:ss"))
        this.setWorkTime(temp)
        .then(() => {
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
      reload() {
        this.isShow = false
        this.isShow = true
      }
    },
    created() {
      this.common.eventBus.$on('WorkTimeBottomSheet.reload', this.reload)
      this.common.eventBus.$on('WorkTimeBottomSheet.close', this.closeBottomSheet)
    }
  }
</script>

<style scoped>
  .worktime-bottom-sheet{
    color: var(--textColor-default);
  }
  .worktime-bottom-sheet__title {
    padding:5px 20px !important;
  }
  .worktime-bottom-sheet__title__date {
    font-size: small;
  }
  .worktime-bottom-sheet__title__date__num {
    font-size: large;
    font-weight: normal;
  }
  .worktime-bottom-sheet__content {
    max-height: 200px;
    min-height: 100px;
  }
  .worktime-bottom-sheet__list {
    display: flex;
    flex-direction: column-reverse;
  }
  .worktime-bottom-sheet__list-item__title {
    color: var(--textColor-default);
    font-weight: normal;
  }
  .worktime-bottom-sheet__list-item__title--holiday {
    color: var(--textColor-holiday);
  }
  .worktime-bottom-sheet__list-item__title--vacation {
    color: var(--textColor-vacation);
  }
  .worktime-bottom-sheet__list-item__breaktime {
    font-size: x-small;
  }

</style>
