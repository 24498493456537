<template>
  <v-bottom-sheet
    v-model="howToUse"
    scrollable
    fullscreen
  >
    <v-card v-if="isShow" class="howtouse">
      <v-card-title class="howtouse__title">
        <div class="mt-1 mb-1">使い方</div>
        <v-spacer/>
        <v-icon @click="closeBottomSheet">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="howtouse__content" >
        <v-card-subtitle class="pl-0">画面の説明</v-card-subtitle>
        <v-expansion-panels tile>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">ヘッダーについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-1-1" width="100%" src="../assets/howToUse/AppHeader.png" @click="openImageDialog('1-1')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>設定メニューを開きます</li>
                    <li>送信ダイアログを開きます</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">サブヘッダーについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-1-2" width="100%" src="../assets/howToUse/SubHeader.png" @click="openImageDialog('1-2')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>表示モードを切り替えます（カレンダー表示 ⇔ リスト表示）</li>
                    <li>選択中の年月です。タップすると年月選択ダイアログを表示します</li>
                    <li>当月の定期券一覧を表示します</li>
                    <li>当月の入力状況一覧を表示します</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">カレンダー表示について</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-1-3" width="100%" src="../assets/howToUse/MonthlyView.png" @click="openImageDialog('1-3')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>現在選択中の日付セルです</li>
                    <li>今日の日付セルです</li>
                    <li>左から右にスワイプすると前月を表示します</li>
                    <li>右から左にスワイプすると次月を表示します</li>
                    <li>選択中の日付です</li>
                    <li>選択中の日付の勤務時間を表示します。タップすると勤務時間設定ダイアログが表示されます</li>
                    <li>選択中の日付の外出旅費を表示します。タップすると外出旅費設定ダイアログが表示されます</li>
                    <li>選択中の日付の仮払経費を表示します。タップすると仮払経費設定ダイアログが表示されます</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">リスト表示について</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-1-4" width="100%" src="../assets/howToUse/ListView.png" @click="openImageDialog('1-4')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>該当の日付の勤務時間を表示します。タップすると勤務時間設定ダイアログが表示されます</li>
                    <li>該当の日付の外出旅費を表示します。タップすると外出旅費設定ダイアログが表示されます</li>
                    <li>該当の日付の仮払経費を表示します。タップすると仮払経費設定ダイアログが表示されます</li>
                    <li>左から右にスワイプすると前月を表示します</li>
                    <li>右から左にスワイプすると次月を表示します</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">フッターについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-1-5" width="100%" src="../assets/howToUse/Footer.png" @click="openImageDialog('1-5')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>勤務時間の設定ボタンです。タップすると勤務時間設定ナビゲーションを表示します</li>
                    <li>外出旅費の設定ボタンです。タップすると外出旅費追加ナビゲーションを表示します</li>
                    <li>仮払経費の設定ボタンです。タップすると仮払経費追加ナビゲーションを表示します</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">設定メニューについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-1-6" width="100%" src="../assets/howToUse/SettingMenu.png" @click="openImageDialog('1-6')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>タップすると標準時間設定ダイアログを開きます。標準時間設定ダイアログでは、勤務時間のデフォルト値を設定することができます</li>
                    <li>タップすると送信テンプレート設定ダイアログを開きます。送信テンプレート設定ダイアログでは、送信ダイアログのデフォルト値を設定することができます</li>
                    <li>タップすると提出履歴を表示します</li>
                    <li>タップすると使い方ガイドを表示します</li>
                    <li>現在ログインしているアカウントからログアウトします</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">勤務時間設定ナビゲーションについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-1-7" width="100%" src="../assets/howToUse/WorkTimeBottomSheet.png" @click="openImageDialog('1-7')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>選択中の日付です</li>
                    <li>直近で設定した勤務時間の履歴が表示されます。タップすると該当の勤務時間情報と同じ情報を選択中の日付に設定します</li>
                    <li>勤務時間設定ダイアログを表示します。勤務時間設定ダイアログでは、新たな勤務時間情報を選択中の日付に設定することができます</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">外出旅費追加ナビゲーションについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-1-8" width="100%" src="../assets/howToUse/TravelsBottomSheet.png" @click="openImageDialog('1-8')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>選択中の日付です</li>
                    <li>直近で追加した外出旅費の履歴が表示されます。タップすると該当の外出旅費情報と同じ情報を選択中の日付に追加します</li>
                    <li>外出旅費設定ダイアログを表示します。外出旅費設定ダイアログでは、新たな外出旅費情報を選択中の日付に追加することができます</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">仮払経費追加ナビゲーションについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-1-9" width="100%" src="../assets/howToUse/ExpensesBottomSheet.png" @click="openImageDialog('1-9')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>選択中の日付です</li>
                    <li>直近で追加した仮払経費の履歴が表示されます。タップすると該当の仮払経費情報と同じ情報を選択中の日付に追加します</li>
                    <li>仮払経費設定ダイアログを表示します。仮払経費設定ダイアログでは、新たな仮払経費情報を選択中の日付に追加することができます</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-subtitle class="pl-0 mt-3">各ダイアログの説明</v-card-subtitle>
        <v-expansion-panels tile>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">勤務時間設定ダイアログについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-2-1" width="100%" src="../assets/howToUse/WorkTimeDialog.png" @click="openImageDialog('2-1')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>選択中の日付です。この日付の勤務時間を設定します</li>
                    <li>
                      <p class="ma-0">種別です。以下の通り、設定してください。</p>
                      <p class="ma-0" style="font-size: small">＜通常＞ 通常の勤務日の場合に設定する</p>
                      <p class="ma-0" style="font-size: small">＜休日＞ 勤務先が休みの場合に設定する</p>
                      <p class="ma-0" style="font-size: small">＜休暇＞ 休暇を取得した場合に設定する</p>
                    </li>
                    <li>勤務を開始した時間を設定してください</li>
                    <li>勤務を終了した時間を設定してください</li>
                    <li>勤務中に取得した休憩時間を設定してください</li>
                    <li>設定した勤務情報に対して、補足事項がある場合に入力してください</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">外出旅費設定ダイアログについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-2-2" width="100%" src="../assets/howToUse/TravelsDialog.png" @click="openImageDialog('2-2')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>選択中の日付です。この日付の外出旅費を追加します</li>
                    <li>用件を入力してください</li>
                    <li>交通手段を入力してください</li>
                    <li>出発地を入力してください</li>
                    <li>目的地を入力してください</li>
                    <li>入力する料金が片道分の料金か往復分の料金かを選択してください</li>
                    <li>利用した料金を入力してください</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">仮払経費設定ダイアログについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-2-3" width="100%" src="../assets/howToUse/ExpensesDialog.png" @click="openImageDialog('2-3')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>選択中の日付です。この日付の仮払経費を追加します</li>
                    <li>勘定科目を入力してください<br>
                      <span style="font-size: x-small">例：会議費、新聞図書費など</span>
                    </li>
                    <li>使用用途の詳細を入力してください<br>
                      <span style="font-size: x-small">例：打ち合わせ ３名 ○○喫茶<br>（会議費の場合）</span>
                    </li>
                    <li>使用した金額を入力してください</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">送信ダイアログについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-2-4" width="100%" src="../assets/howToUse/SubmitDialog.png" @click="openImageDialog('2-4')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>メールの宛先を設定します</li>
                    <li>当月の入力状況一覧を表示します</li>
                    <li>提出する書類を選択します。但し、登録情報がない書類は、選択できません</li>
                    <li>コメントを設定します</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">標準時間設定ダイアログについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-2-5" width="100%" src="../assets/howToUse/StandardWorkTimeDialog.png" @click="openImageDialog('2-5')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>勤務開始時間の初期値を設定します</li>
                    <li>勤務終了時間の初期値を設定します</li>
                    <li>休憩時間の初期値を設定します</li>
                    <li>オンにすると、毎月、上記で設定した勤務時間を自動で一括登録します。（毎月初回アクセス時に登録）</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">送信テンプレート設定ダイアログについて</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="howtouse__item__image">
                  <v-img id="howtouse-image-2-6" width="100%" src="../assets/howToUse/SubmitSettingDialog.png" @click="openImageDialog('2-6')"/>
                </v-col>
                <v-col cols="8" class="howtouse__item__text">
                  <ol>
                    <li>宛先の初期値を設定します</li>
                    <li>コメントの初期値を設定します</li>
                  </ol>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-subtitle class="pl-0 mt-3">よくある質問</v-card-subtitle>
        <v-expansion-panels tile>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-subtitle class="pa-0">定期券について</v-card-subtitle>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" class="howtouse__item__text">
                  定期券は、前月に登録されている分を自動的に引き継ぎます（毎月の初回アクセス時にコピーします）
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="imageDialog"
      max-width="600px"
    >
      <img :src="imageDialogSrc" @click="imageDialog=false"/>
    </v-dialog>    
  </v-bottom-sheet>
</template>

<script>

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      howToUse: false,
      isShow: true,
      imageDialog: false,
      imageDialogSrc: undefined,
    }),
    methods: {
      openBottomSheet() {
        this.common.eventBus.$emit('App.overflow.add')
        this.howToUse = true
      },
      closeBottomSheet() {
        this.common.eventBus.$emit('App.overflow.remove')
        this.howToUse = false
      },
      openImageDialog(index) {
        let element = document.querySelector("#howtouse-image-" + index + " .v-image__image")
        if (element) {
          this.imageDialogSrc = element.style.backgroundImage.replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
          this.imageDialog = true
        }
      },
      reload() {
        this.isShow = false
        this.isShow = true
      },
    },
    created() {
      this.common.eventBus.$on('HowToUse.reload', this.reload)
      this.common.eventBus.$on('HowToUse.open', this.openBottomSheet)
      this.common.eventBus.$on('HowToUse.close', this.closeBottomSheet)
    }
  }
</script>

<style scoped>
  .howtouse__item__image{
    max-height: 500px;
  }
</style>
