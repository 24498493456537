<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      persistent
      fullscreen
      hide-overlay
      scrollable
    >
      <v-card v-if="isShow">
        <div class="monthly-statistics__header">
          <v-card-title>
            <span class="monthly-statistics__title">
              <span class="text-h5">{{common.select.year}}</span>年
              <span class="text-h5">{{common.select.month}}</span>月
            </span>
          </v-card-title>
          <v-icon @click="isOpen = false" dark>mdi-close</v-icon>
        </div>
        <v-tabs class="monthly-statistics__tabs" v-model="tab" centered fixed-tabs show-arrows="">
          <v-tab><span class="monthly-statistics__tabs-title">勤務時間</span></v-tab>
          <v-tab><span class="monthly-statistics__tabs-title">外出旅費</span></v-tab>
          <v-tab><span class="monthly-statistics__tabs-title">仮払経費</span></v-tab>
          <v-tab><span class="monthly-statistics__tabs-title">定期券</span></v-tab>
        </v-tabs>
        <v-card-text class="pa-0">
          <v-tabs-items class="monthly-statistics__tab-items" v-model="tab">
            <v-tab-item class="monthly-statistics__tab-item pa-3">
              <div class="monthly-statistics__tab-item__header">
                <div class="ma-2 mr-4 monthly-statistics__tab-item__header__text">
                  <div>稼働日数</div>
                  <div class="mt-1">
                    <span class="ml-2 mr-2 monthly-statistics__tab-item__header__value">{{getWorkingDay()}}</span>
                    日
                  </div>
                </div>
                <div class="ma-2 ml-4 monthly-statistics__tab-item__header__text">
                  <div>稼働時間</div>
                  <div class="mt-1">
                    <span class="ml-2 mr-2 monthly-statistics__tab-item__header__value">{{getTotalTime()}}</span>
                    時間
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="monthly-statistics__tab-item__content">
                <v-list>
                  <div 
                  v-for="(worktime, key) in data.worktimes"
                  :key="key">
                    <div class="mt-3 monthly-statistics__tab-item__content__title" 
                      v-if="worktime.date" >
                      <span class="monthly-statistics__tab-item__content__title__value">{{worktime.date.format("YYYY")}}</span>年
                      <span class="monthly-statistics__tab-item__content__title__value">{{worktime.date.format("M")}}</span>月
                      <span class="monthly-statistics__tab-item__content__title__value">{{worktime.date.format("D")}}</span>日
                      (<span >{{common.week[worktime.date.format("d")]}}</span>)
                    </div>
                    <WorktimeListItems :common="common" :data="data" :dialog="dialog" :displayItem="{date: worktime.date, worktimes: [worktime]}"/>
                  </div>
                </v-list>
              </div>
            </v-tab-item>
            <v-tab-item class="monthly-statistics__tab-item pa-3">
              <div class="monthly-statistics__tab-item__header">
                <div class="ma-2 mr-4 monthly-statistics__tab-item__header__text">
                  <div>合計件数</div>
                  <div class="mt-1">
                    <span class="ml-2 mr-2 monthly-statistics__tab-item__header__value">{{Object.keys(data.travels).length}}</span>
                    件
                  </div>
                </div>
                <div class="ma-2 ml-4 monthly-statistics__tab-item__header__text">
                  <div>合計金額</div>
                  <div class="mt-1">
                    <span class="ml-2 mr-2 monthly-statistics__tab-item__header__value">{{getTravelsTotalAmount().toLocaleString()}}</span>
                    円
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="monthly-statistics__tab-item__content">
                <v-list>
                  <div 
                  v-for="(travels, key) in displayTravels"
                  :key="key">
                    <div class="mt-3 monthly-statistics__tab-item__content__title" 
                      v-if="travels.length" >
                      <span class="monthly-statistics__tab-item__content__title__value">{{travels[0].date.format("YYYY")}}</span>年
                      <span class="monthly-statistics__tab-item__content__title__value">{{travels[0].date.format("M")}}</span>月
                      <span class="monthly-statistics__tab-item__content__title__value">{{travels[0].date.format("D")}}</span>日
                      (<span >{{common.week[travels[0].date.format("d")]}}</span>)
                    </div>
                    <TravelListItems :common="common" :data="data" :dialog="dialog" :displayItem="{date: travels[0].date, travels: travels}"/>
                  </div>
                </v-list>
              </div>
            </v-tab-item>
            <v-tab-item class="monthly-statistics__tab-item pa-3">
              <div class="monthly-statistics__tab-item__header">
                <div class="ma-2 mr-4 monthly-statistics__tab-item__header__text">
                  <div>合計件数</div>
                  <div class="mt-1">
                    <span class="ml-2 mr-2 monthly-statistics__tab-item__header__value">{{Object.keys(data.expenses).length}}</span>
                    件
                  </div>
                </div>
                <div class="ma-2 ml-4 monthly-statistics__tab-item__header__text">
                  <div>合計金額</div>
                  <div class="mt-1">
                    <span class="ml-2 mr-2 monthly-statistics__tab-item__header__value">{{getExpensesTotalAmount().toLocaleString()}}</span>
                    円
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="monthly-statistics__tab-item__content">
                <v-list>
                  <div 
                  v-for="(expense, key) in displayExpenses"
                  :key="key">
                    <div class="mt-3 monthly-statistics__tab-item__content__title" 
                      v-if="expense[0].date" >
                      <span class="monthly-statistics__tab-item__content__title__value">{{expense[0].date.format("YYYY")}}</span>年
                      <span class="monthly-statistics__tab-item__content__title__value">{{expense[0].date.format("M")}}</span>月
                      <span class="monthly-statistics__tab-item__content__title__value">{{expense[0].date.format("D")}}</span>日
                      (<span >{{common.week[expense[0].date.format("d")]}}</span>)
                    </div>
                    <ExpenseListItems :common="common" :data="data" :dialog="dialog" :displayItem="{date: expense[0].date, expenses: expense}"/>
                  </div>
                </v-list>
              </div>
            </v-tab-item>
            <v-tab-item class="monthly-statistics__tab-item pa-3">
              <div class="monthly-statistics__tab-item__header">
                <div class="ma-2 mr-4 monthly-statistics__tab-item__header__text">
                  <div>合計件数</div>
                  <div class="mt-1">
                    <span class="ml-2 mr-2 monthly-statistics__tab-item__header__value">{{displayCommuterPass.length}}</span>
                    件
                  </div>
                </div>
                <div class="ma-2 ml-4 monthly-statistics__tab-item__header__text">
                  <div>合計金額</div>
                  <div class="mt-1">
                    <span class="ml-2 mr-2 monthly-statistics__tab-item__header__value">{{getCommuterPassTotalAmount().toLocaleString()}}</span>
                    円
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="monthly-statistics__tab-item__content">
                <div class="mt-5" >
                  <CommuterPassListItems :common="common" :data="data" :dialog="dialog" :displayItem="{commuterPass: displayCommuterPass}"/>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import WorktimeListItems from './partial/WorktimeListItems.vue';
  import TravelListItems from './partial/TravelListItems.vue';
  import ExpenseListItems from './partial/ExpenseListItems.vue';
  import CommuterPassListItems from './partial/CommuterPassListItems.vue';

  export default {
    props: ["common", "data", "dialog"],
    components: {
      WorktimeListItems,
      TravelListItems,
      ExpenseListItems,
      CommuterPassListItems,
    },
    data: () => ({
      isShow: true,
      isOpen: false,
      tab: null,
      displayTravels: [],
      displayExpenses: [],
      displayCommuterPass: [],
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove');
        this.isOpen = true
        this.displayTravels = this.getDisplayTravels()
        this.displayExpenses = this.getDisplayExpenses()
        this.displayCommuterPass = this.data.commuterPass.thisMonth
      },
      getDisplayTravels() {
        let travels = this.sort(Object.entries(this.data.travels))
        let displayTravels = {}
        for (let travel of travels) {
          let key = travel[1].date.format("YYYY-MM-DD")
          let travelItems = displayTravels[key] || []
          travelItems.push(travel[1])
          displayTravels[key] = travelItems
        }
        return displayTravels
      },
      getDisplayExpenses() {
        let expenses = this.sort(Object.entries(this.data.expenses))
        let displayExpenses = {}
        for (let expense of expenses) {
          let key = expense[1].date.format("YYYY-MM-DD")
          let expenseItems = displayExpenses[key] || []
          expenseItems.push(expense[1])
          displayExpenses[key] = expenseItems
        }
        return displayExpenses
      },
      getWorkingDay() {
        let workingday = 0
        for (let [, worktime] of Object.entries(this.data.worktimes)) {
          if (worktime && worktime.totaltime && worktime.daytype == 0) {
            workingday++
          }
        }
        return workingday
      },
      getTotalTime() {
        let totaltime = 0
        for (let [, worktime] of Object.entries(this.data.worktimes)) {
          if (worktime && worktime.totaltime && worktime.daytype == 0) {
            totaltime = totaltime + worktime.totaltime
          }
        }
        return totaltime
      },
      getTravelsTotalAmount() {
        let totalAmount = 0
        for (let [, travel] of Object.entries(this.data.travels)) {
          if (travel && travel.amount) {
            totalAmount += travel.amount
          }
        }
        return totalAmount
      },
      getExpensesTotalAmount() {
        let totalAmount = 0
        for (let [, expense] of Object.entries(this.data.expenses)) {
          if (expense && expense.amount) {
            totalAmount += expense.amount
          }
        }
        return totalAmount
      },
      getCommuterPassTotalAmount() {
        let totalAmount = 0
        for (let commuterPass of this.data.commuterPass.thisMonth) {
          if (commuterPass && commuterPass.amount) {
            totalAmount += commuterPass.amount
          }
        }
        return totalAmount
      },
      reload() {
        this.displayTravels = this.getDisplayTravels()
        this.displayExpenses = this.getDisplayExpenses()
        this.displayCommuterPass = this.data.commuterPass.thisMonth
        this.isShow = false
        this.isShow = true
      },
      sort(obj) {
        let ret = obj.sort(function(a, b){
          if(a[1].date.format("YYYYMMDD") < b[1].date.format("YYYYMMDD")) return -1
          if(a[1].date.format("YYYYMMDD") > b[1].date.format("YYYYMMDD")) return 1
          return 0
        })
        return ret
      },
    },
    created() {
      this.common.eventBus.$on('MonthlyStatistics.open', this.initialize);
      this.common.eventBus.$on('MonthlyStatistics.reload', this.reload);
    },
}
</script>

<style scoped>
  .monthly-statistics__header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 8px;
    background-color:var(--primaryColor);
  }
  .monthly-statistics__title {
    font-size: medium;
    color: white;
  }
  .monthly-statistics__tabs {
    flex: 0;
  }
  .monthly-statistics__tabs-title {
    font-size: small;
  }
  .monthly-statistics__tab-items{
    height:100%;
  }
  .monthly-statistics__tab-item {
    height:100%;
    display: flex;
    flex-direction: column; 
  }
  .monthly-statistics__tab-item__header {
    display: flex;
    justify-content: center;
    flex: 0 auto;
  }
  .monthly-statistics__tab-item__header__text {
    text-align: center;
  }
  .monthly-statistics__tab-item__header__value {
    font-size: x-large;
  }
  .monthly-statistics__tab-item__content {
    overflow-y: scroll;
    flex: 1 0;
  }
  .monthly-statistics__tab-item__content__title {
    font-size: x-small;
    color: var(--textColor-default);
  }
  .monthly-statistics__tab-item__content__title__value {
    font-size: large;
  }
</style>
