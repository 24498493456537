import Vue from 'vue'
import Router from "vue-router"
import Main from "@/components/Main.vue"
import Login from "@/components/Login.vue"

Vue.use(Router)

//export default new Router({
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    //ルーティングの設定
    {
      path: '/',            
      component: Main,
      name: 'home',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: process.env.VUE_APP_LOGIN_PAGE_PATH,            
      component: Login,
      name: 'login',
    }
  ]
});

function getCookie(key) {
    var cookies = document.cookie
    var cookiesArray = cookies.split(';')
    for(var cookie of cookiesArray){
        var cookieArray = cookie.split('=')
        if(cookieArray[0] == key && cookieArray.length > 1){
            return cookieArray;
        }
    }
    return undefined;
}

function isAuthenticated() {
    return getCookie('asil.token') != undefined
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({
        path: process.env.VUE_APP_LOGIN_PAGE_PATH,
      })
    } else {
      next(); 
    }
  } else {
    next(); 
  }
});

export default router