<template>
  <v-main class="list-view" v-if="isShow">
    <v-list class="list-view__calendars" >
      <div
        :id="'list-view__calendars-item--' + day.day"
        class="list-view__calendars-item"
        v-for="(day, index) in calendars"
        :key="index"
        :class="{
          'list-view__calendars-item--top': index == 0, 
          'list-view__calendars-item--selectday': common.select.day == day.day
        }"
        @click="selectDate(day)">
        <div class="list-view__calendars-item__content" >
          <div  class="list-view__date pb-1"
            :class="{
              'list-view__date--selectday': common.select.day == day.day,
              'list-view__day--sunday' : day.dayOfWeek == 0 || isHoliday(day.date) || (data.worktimes && data.worktimes[day.day] && data.worktimes[day.day].daytype == 1), 
              'list-view__day--saturday': day.dayOfWeek == 6 && !isHoliday(day.date),
            }">
            <span class="list-view__day">{{day.day}}</span>
            <span>
              (
              <span>
                {{ common.week[day.dayOfWeek] }}
                </span>
              )
            </span>
          </div>
          <div style="width: calc(100% - 50px); " class="list-view__content pt-2 pb-2"
            v-if="getDisplayItem(day.date).worktimes.length || getDisplayItem(day.date).travels.length || getDisplayItem(day.date).expenses.length">
            <WorktimeListItems :common="common" :data="data" :dialog="dialog" :displayItem="getDisplayItem(day.date)"/>
            <TravelListItems :common="common" :data="data" :dialog="dialog" :displayItem="getDisplayItem(day.date)"/>
            <ExpenseListItems :common="common" :data="data" :dialog="dialog" :displayItem="getDisplayItem(day.date)"/>
          </div>
          <div v-else-if="isHoliday(day.date)" class="pt-2 pb-2 pl-3 list-view__content--holiday">
            <span>{{getHolidayName(day.date)}}</span>
          </div>
        </div>
      </div>

    </v-list>
  </v-main>
</template>

<script>
  import moment from 'moment'
  import WorktimeListItems from './partial/WorktimeListItems.vue'
  import TravelListItems from './partial/TravelListItems.vue'
  import ExpenseListItems from './partial/ExpenseListItems.vue'

  export default {
    components: {
      WorktimeListItems,
      TravelListItems,
      ExpenseListItems,
    },
    props: ["common", "data", "dialog"],
    data: () => ({
      isChangeMonth: false,
      isShow: true,
    }),
    methods: {
      initialize: function() {
      },
      changeMonth() {
        this.isChangeMonth = true
        this.initialize()
      },
      getCalendar() {
        let startDate = this.getStartOfMonth()
        let endDate = this.getEndOfMonth()
        let calendars = []
        for (let day = 0; day < endDate.get("date"); day++) {
          calendars.push({
            date: moment(new Date(startDate.get("year"), startDate.get("month"), startDate.get("date"))),
            day: startDate.get("date"),
            dayOfWeek: startDate.format("d"),
            isCurrentMonth: startDate.format("M") == this.common.select.month,
            isToday: startDate.format("YYYYMMDD") == moment().format("YYYYMMDD"),
            isSelected: startDate.format("YYYYMMDD") == this.common.select.date.format("YYYYMMDD"),
            travelsCount: this.getTravelsByDate(startDate).length,
            expensesCount: this.getExpensesByDate(startDate).length,
          })
          startDate.add(1, "days")
        }
        return calendars
      },
      selectDate(day) {
        if (day.isCurrentMonth) {
          this.setSelectDate(this.common.select.year, this.common.select.month, day.day)
        }
      },
      getDisplayItem(date) {
        return {
          date: date,
          worktimes: this.getWorkTimesByDate(date),
          travels: this.getTravelsByDate(date),
          expenses: this.getExpensesByDate(date),
        }
      },
      scrollSelectedPostion() {
        let selectedElem = document.getElementById("list-view__calendars-item--" + this.common.select.day)
        if (selectedElem) {
          let selectedPosition = selectedElem.getBoundingClientRect().top - 200
          window.scrollTo({
              top: selectedPosition,
          })
        }
      },
      reload() {
        this.common.eventBus.$emit('WorkTimeBottomSheet.reload')
        this.isShow = false
        this.isShow = true
      }
    },
    computed: {
      calendars() {
        return this.getCalendar()
      }
    },
    created() {
      this.initialize()
      this.common.eventBus.$on('ListView.initialize', this.changeMonth)
      this.common.eventBus.$on('ListView.reload', this.reload)
    },
    mounted() {
      this.$nextTick(function () {
        this.scrollSelectedPostion()
      })
    },
    updated() {
      this.$nextTick(function () {
        if (this.isChangeMonth) {
          this.scrollSelectedPostion()
          this.isChangeMonth = false
        }
      })
    }
}
</script>

<style scoped>
  .list-view {
    margin-top: 93px;
    margin-bottom: 100px;
    color:var(--textColor-default);
    text-align: center; 
  }
  .list-view__calendars-item {
    border-bottom: 0.5px solid var(--borderColor-default);
  }
  .list-view__calendars-item--top {
    border-top: 0.5px solid var(--borderColor-default);
  }
  .list-view__calendars-item--selectday {
    background-color: var(--selectdayColor);
  }
  .list-view__calendars-item__content {
    min-height: 75px;
    display: flex;
  }
  .list-view__date {
    display: flex; 
    flex-direction: column; 
    font-size: small; 
    background-color: var(--backgroundColor-gray); 
    width: 50px;
    align-items: center;
    justify-content: center;
  }
  .list-view__date--selectday {
    background-color: var(--secondaryColor);
  }
  .list-view__day {
    font-size: medium;
  }
  .list-view__day--sunday {
    color: var(--textColor-week-sunday);
  }
  .list-view__day--saturday {
    color: var(--textColor-week-saturday);
  }
  .list-view__content--holiday {
    color: var(--textColor-holiday);
    display: flex;
    align-items: center;
  }

</style>
