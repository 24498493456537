<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      max-width="600px"
      scrollable>
      <v-form ref="submitDialogForm">
        <v-card>
          <v-card-title>
            <span class="submit-dialog__title">
              <span class="text-h5">{{common.select.year}}</span>年
              <span class="text-h5">{{common.select.month}}</span>月
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12" class="pb-0">
                  <v-select
                    v-model="mailto"
                    :items="getAddressList()"
                    item-text="name"
                    item-value="address"
                    label="宛先"
                    multiple
                    chips
                    prepend-icon="mdi-email-outline"
                    small-chips
                    :rules="rules"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-2 pb-0" style="display: flex; align-items: center;">
                  <p class="mb-0" >提出内容</p>
                  <v-icon 
                    class="ml-2"
                    :color="common.color.primary" 
                    @click="openMonthlyStatistics"
                    small>
                    mdi-information-outline
                  </v-icon>
                </v-col>
                <v-col
                  cols="12" class="pt-1 pb-1">
                  <v-checkbox
                    v-model="documents"
                    label="業務日報"
                    value="worktimes"
                    dense
                    hide-details
                    class="mt-0"
                    :disabled="Object.keys(this.data.worktimes).length == 0"
                    :rules="rules"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12" class="pt-0 pb-1">
                  <v-checkbox
                    v-model="documents"
                    label="外出旅費請求書"
                    value="travels"
                    dense
                    hide-details
                    class="mt-0"
                    :disabled="this.data.travels.length == 0 && this.data.commuterPass.thisMonth.length == 0"
                    :rules="rules"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12" class="pt-0 pb-1">
                  <v-checkbox
                    v-model="documents"
                    label="仮払経費明細表"
                    value="expenses"
                    dense
                    class="mt-0"
                    :disabled="this.data.expenses.length == 0"
                    :rules="rules"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12">
                  <v-textarea
                    v-model="comment"
                    label="コメント"
                    rows="5"
                    outlined
                    hide-details>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="common.color.primary"
              text
              @click="isOpen = false">
              キャンセル
            </v-btn>
            <v-btn
              :color="common.color.primary"
              text
              @click="clickSubmit()">
              送信
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      isOpen: false,
      year: null,
      month: null,
      day: null,
      mailto: null,
      comment: null,
      timeout: 5000,
      documents: [],
      addresslist: [],
      rules: [
        value => !!(value && value.length) || 'Required.',
      ],
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove')
        if (this.$refs.submitDialogForm) {
          this.$refs.submitDialogForm.resetValidation()
        }
        var mailtoStr = this.getSettingsValue(this.common.settings.submit.mailto)
        this.mailto = mailtoStr ? mailtoStr.split(',') : undefined
        this.comment = this.getSettingsValue(this.common.settings.submit.comment)

        this.documents = []
        if (Object.keys(this.data.worktimes).length) {
          this.documents.push("worktimes")
        }
        if (this.data.travels.length || this.data.commuterPass.thisMonth.length) {
          this.documents.push("travels")
        }
        if (this.data.expenses.length) {
          this.documents.push("expenses")
        }
        this.isOpen = true
      },
      clickSubmit() {
        let isSuccess = this.$refs.submitDialogForm.validate()
        if (isSuccess) {
          this.submit(
            this.common.select.date.format('YYYY-MM'), 
            this.mailto.join(','), this.documents, this.comment)
          .then(() =>{
            this.isOpen = false
            this.success()
          })
          .catch(err => {
            this.errorProcess(err)
          })
        }
      },
      success() {
        this.dialog.confirm = {
          title: "通知",
          message: "メールの送信が完了しました。",
          ok: "OK",
          okAction: function(){
          },
        }
        this.common.eventBus.$emit('ConfirmDialog.open')
      },
      openMonthlyStatistics() {
        this.common.eventBus.$emit('MonthlyStatistics.open')
      },
    },
    created() {
      this.common.eventBus.$on('SubmitDialog.open', this.initialize)
    },
  }
</script>

<style scoped>
  .submit-dialog__title {
    font-size: medium;
  }
</style>
