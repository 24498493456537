<template>
  <v-bottom-sheet
    v-model="travelsBottomSheet"
    inset
    scrollable
    hide-overlay
    no-click-animation
    persistent
    internal-activator
  >
    <template v-slot:activator="{ attrs }">
      <v-btn
        :color="common.color.travel" block small dark
        v-bind="attrs"
        @click="openBottomSheet">
        <v-icon dark small left>
          mdi-plus
        </v-icon>
        外出旅費
      </v-btn>
    </template>
    <v-card v-if="isShow" class="travels-bottom-sheet" >
      <v-card-title class="travels-bottom-sheet__title">
        <span class="travels-bottom-sheet__title__date">
          <span class="travels-bottom-sheet__title__date__num" >{{common.select.year}}</span>年
          <span class="travels-bottom-sheet__title__date__num" >{{common.select.month}}</span>月
          <span class="travels-bottom-sheet__title__date__num" >{{common.select.day}}</span>日
          (<span class="ml-1 mr-1">{{common.week[common.select.dayOfWeek]}}</span>)
        </span>
        <v-spacer/>
        <v-icon @click="closeBottomSheet">
          mdi-chevron-down
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="travels-bottom-sheet__content">
        <v-list dense>
          <v-subheader>履歴から追加</v-subheader>
          <v-list-item-group
            class="travels-bottom-sheet__list"
            :color="common.color.primary">          
            <v-list-item
              v-for="(travel, key) in data.history.travels"
              :key="key">
              <v-list-item-content 
                @click="insertTravel(travel)">
                <v-list-item-subtitle >
                  {{travel.discription}}
                </v-list-item-subtitle>
                <v-list-item-title class="travels-bottom-sheet__list-item__title">
                  <span>{{travel.startSection}}</span>
                  <v-icon small class="ml-1 mr-1">{{travel.sectionType ? 'mdi-arrow-u-left-bottom': 'mdi-arrow-right'}}</v-icon>
                  <span>{{travel.endSection}}</span>
                  <span class="travels-bottom-sheet__list-item__transportation">
                    （{{travel.transportation}}）
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle >
                  {{travel.amount.toLocaleString()}}<span class="ml-1">円</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon small @click="removeHistoryItem(travel)">
                  mdi-close
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-5">
        <v-spacer/>
        <v-btn :color="common.color.travel" dark block
          @click="openTravelsDialog">
          <v-icon dark small left>
            mdi-plus
          </v-icon>
          新たに外出旅費を設定する
        </v-btn>
        <v-spacer/>
      </v-card-actions>        
    </v-card>
  </v-bottom-sheet>
</template>

<script>

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      travelsBottomSheet: false,
      isShow: true,
    }),
    methods: {
      openBottomSheet() {
        this.common.eventBus.$emit('App.overflow.add')
        this.travelsBottomSheet = true
      },
      closeBottomSheet() {
        this.common.eventBus.$emit('App.overflow.remove')
        this.travelsBottomSheet = false
      },
      openTravelsDialog() {
        this.dialog.year = this.common.select.year
        this.dialog.month = this.common.select.month
        this.dialog.day = this.common.select.day
        this.dialog.travel = {}
        this.common.eventBus.$emit('TravelsDialog.open')
      },
      removeHistoryItem(travel) {
        this.deleteTravelsHistory(travel.id)
        .then(() => {
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
      insertTravel(travel) {
        let temp = { ...travel }
        temp.id = undefined
        temp.date = this.common.select.date
        this.setTravel(temp)
        .then(() => {
          let day = this.common.select.day
          this.common.select.day = undefined
          this.common.select.day = day
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
      reload() {
        this.isShow = false
        this.isShow = true
      },
    },
    created() {
      this.common.eventBus.$on('TravelsBottomSheet.reload', this.reload)
      this.common.eventBus.$on('TravelsBottomSheet.close', this.closeBottomSheet)
    }
  }
</script>

<style scoped>
  .travels-bottom-sheet{
    color: var(--textColor-default);
  }
  .travels-bottom-sheet__title {
    padding:5px 20px !important;
  }
  .travels-bottom-sheet__title__date {
    font-size: small;
  }
  .travels-bottom-sheet__title__date__num {
    font-size: large;
    font-weight: normal;
  }
  .travels-bottom-sheet__list {
    display: flex;
    flex-direction: column-reverse;
  }
  .travels-bottom-sheet__content {
    max-height: 200px;
    min-height: 100px;
  }
  .travels-bottom-sheet__list-item__title {
    color: var(--textColor-default);
    font-weight: normal;
  }
  .travels-bottom-sheet__list-item__transportation {
    font-size: x-small;
  }
</style>
