<template>
  <v-app-bar v-if="isShow" :color="common.color.primary" height="50px" flat fixed dense>
    <v-icon v-if="common.account.token" color="white" @click="openSideMenu">mdi-cog</v-icon>
    <v-spacer></v-spacer>
    <v-img max-height="50%" max-width="100px" contain src="../assets/logo.png" class=""/>
    <v-spacer></v-spacer>
    <v-icon v-if="common.account.token" color="white" @click="openSubmitDialog">{{submited ? "mdi-email-check-outline" : "mdi-email-arrow-right-outline"}}</v-icon>
  </v-app-bar>
</template>

<script>
  export default {
    props: ["common"],
    data: () => ({
      isShow: true,
      submited: false
    }),
    methods: {
      openSubmitDialog() {
        this.common.eventBus.$emit('SubmitDialog.open')
      },
      openSideMenu() {
        this.common.eventBus.$emit('SideMenu.open')
      },
      setSubmited(){
        this.submited = true
        this.reload()
      },
      setNotSubmited(){
        this.submited = false
        this.reload()
      },
      reload() {
        this.isShow = false
        this.isShow = true
      }
    },
    created() {
      this.common.eventBus.$on('AppHeader.reload', this.reload)
      this.common.eventBus.$on('AppHeader.submited', this.setSubmited)
      this.common.eventBus.$on('AppHeader.notSubmited', this.setNotSubmited)
    },
  }
</script>

<style scoped>
</style>
