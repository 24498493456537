<template>
  <div class="expense-list-items ml-1" 
    v-if="
      isShow &&
      displayItem && 
      displayItem.expenses && 
      displayItem.expenses.length">
    <v-list-item
      class="expense-list-items__item mb-1 mt-1"
      v-for="(expense, index) in displayItem.expenses"
      :key="'expense-' + index"
      three-line
      @click="openExpensesDialog(index)"
      >
      <v-list-item-content class="expense-list-items__item__content">
        <v-list-item-subtitle>
          {{expense.accountTitle}}
        </v-list-item-subtitle>
        <v-list-item-title class="expense-list-items__item__title mt-1" >
          <span class="expense-list-items__item__discription">
            {{expense.discription}}
          </span>
        </v-list-item-title>
        <v-list-item-subtitle class="mt-1">
          {{expense.amount.toLocaleString()}}<span class="ml-1">円</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action style="align-self: center;">
        <v-btn icon @click.stop="remove(expense.id)">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>        
</template>

<script>

  export default {
    props: ["common", "data", "dialog", "displayItem"],
    data: () => ({
      isShow: true,
    }),
    methods: {
      openExpensesDialog(index) {
        this.dialog.year = this.displayItem.date.get("year")
        this.dialog.month = this.displayItem.date.get("month") + 1
        this.dialog.day = this.displayItem.date.get("date")
        this.dialog.expense = this.displayItem.expenses[index]
        this.common.eventBus.$emit('ExpensesDialog.open')
      },
      reload() {
        this.isShow = false
        this.isShow = true
      },
      remove(id) {
        this.deleteExpense(id)
        .then(() => {
          this.isOpen = false
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
    },
    created() {
      this.common.eventBus.$on('ExpenseListItems.reload', this.reload)
    },
}
</script>

<style scoped>
  .expense-list-items {
    text-align: left;
  }
  .expense-list-items__item {
    border-left: 5px solid var(--expensesColor);
  }
  .expense-list-items__item__content {
    color: var(--textColor-default)
  }
  .expense-list-items__item__title {
    display: flex;
    align-items: center;
  }
</style>
