<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="600px"
      scrollable
    >
      <v-form ref="worktimeDialogForm">
        <v-card>
          <v-card-title>
            <span class="worktime-dialog__title">
              <span class="text-h5">{{year}}</span>年
              <span class="text-h5">{{month}}</span>月
              <span class="text-h5">{{day}}</span>日
              ({{common.week[common.select.dayOfWeek]}})
            </span>
            <v-spacer></v-spacer>
            <v-icon right @click="remove()">
              mdi-trash-can-outline
            </v-icon>
          </v-card-title>
          <v-card-subtitle class="mt-1 pb-2">
            勤務時間
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="daytype"
                    :items="common.daytype"
                    prepend-icon="mdi-calendar-star"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-dialog
                    ref="starttimeDialog"
                    v-model="starttimeDialog"
                    :return-value.sync="starttime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="starttime"
                        label="開始時間"
                        prepend-icon="mdi-clock-time-nine-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :disabled="daytype != common.daytype[0]"
                        :rules="daytype == common.daytype[0] ? rules : []"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="starttimeDialog"
                      v-model="starttime"
                      full-width
                      format="24hr"
                      :allowed-minutes="allowedStep"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="starttimeDialog = false"
                      >
                        キャンセル
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.starttimeDialog.save(starttime);changedStartEndTime()"
                      >
                        設定
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-dialog
                    ref="endtimeDialog"
                    v-model="endtimeDialog"
                    :return-value.sync="endtime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endtime"
                        label="終了時間"
                        prepend-icon="mdi-clock-time-six-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :disabled="daytype != common.daytype[0]"
                        :rules="daytype == common.daytype[0] ? rules : []"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="endtimeDialog"
                      v-model="endtime"
                      full-width
                      format="24hr"
                      :allowed-minutes="allowedStep"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="endtimeDialog = false"
                      >
                        キャンセル
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endtimeDialog.save(endtime);changedStartEndTime()"
                      >
                        設定
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="breaktime"
                    :items="breaktimeList"
                    v-if="breaktimeList"
                    label="休憩時間"
                    prepend-icon="mdi-coffee-outline"
                    :disabled="daytype != common.daytype[0]"
                    :rules="daytype == common.daytype[0] ? rules : []"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="remarks"
                    label="備考"
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="common.color.primary"
              text
              @click="isOpen = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              :color="common.color.primary"
              text
              @click="save()"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    props: ["common", "data", "dialog"],
    data: () => ({
      isOpen: false,
      year: null,
      month: null,
      day: null,
      starttime: null,
      endtime: null,
      breaktime: null,
      remarks: null,
      daytype: null,
      starttimeDialog: false,
      endtimeDialog: false,
      breaktimeList: [0],
      rules: [
        value => !!value || 'Required.',
      ],      
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove')
        if (this.$refs.worktimeDialogForm) {
          this.$refs.worktimeDialogForm.resetValidation()
        }
        this.year = this.dialog.year
        this.month = this.dialog.month
        this.day = this.dialog.day
        this.starttime = this.dialog.worktime.starttime ? this.dialog.worktime.starttime.format("HH:mm") : this.getSettingsValue(this.common.settings.standardWorktime.starttime)
        this.endtime = this.dialog.worktime.starttime ? this.dialog.worktime.endtime.format("HH:mm") : this.getSettingsValue(this.common.settings.standardWorktime.endtime)
        this.breaktimeList = this.getBreakTimeList(this.starttime, this.endtime)
        this.daytype = this.dialog.worktime.daytype ? this.common.daytype[this.dialog.worktime.daytype] : this.common.daytype[0]
        this.breaktime = this.dialog.worktime.breaktime || Number(this.getSettingsValue(this.common.settings.standardWorktime.breaktime))
        this.remarks = this.dialog.worktime.remarks
        this.isOpen = true
      },
      save() {
        let isSuccess = this.$refs.worktimeDialogForm.validate()
        if (isSuccess) {
          let date = this.getCurrentDate()
          let worktime = {
            date: this.getCurrentDate(),
            daytype: Number(this.getDayTypeIndex(this.daytype)),
            starttime: this.getDateTime(date, this.starttime),
            endtime: this.getDateTime(date, this.endtime),
            breaktime: this.breaktime,
            remarks: this.remarks,
          }
          if (worktime.starttime && worktime.endtime && 
            worktime.endtime.isSameOrBefore(worktime.starttime)) {
            worktime.endtime.add(1, "days")
          }
          worktime.totaltime = this.getTotalTime(worktime.starttime, worktime.endtime, worktime.breaktime)

          new Promise((resolve) => {
            let promise = []
            promise.push(this.setWorkTime(worktime))
            promise.push(this.setWorkTimesHistory(worktime))
            let sameWorktime = this.getSameWorkTimesHistory(worktime)
            if (sameWorktime) {
              promise.push(this.deleteWorkTimesHistory(sameWorktime.id))
            }
            Promise.all(promise)
            .then(() => {
              this.isOpen = false
              this.common.eventBus.$emit('App.reload')
              resolve()
            })
            .catch(err => {
              this.errorProcess(err)
            })
          })
        }
      },
      remove() {
        this.deleteWorkTime(this.getCurrentDate())
        .then(() => {
          this.isOpen = false
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      },
      getCurrentDate() {
        return moment(new Date(this.year, this.month - 1, this.day))
      },
      changedStartEndTime(){
        this.breaktimeList = this.getBreakTimeList(this.starttime, this.endtime)
      },
    },
    created() {
      this.common.eventBus.$on('WorkTimeDialog.open', this.initialize)
    },
}
</script>

<style scoped>
  .worktime-dialog__title {
    font-size: medium;
  }
</style>
