<template>
  <div justify="center">
    <v-dialog
      v-model="isOpen"
      persistent
      fullscreen
    >
        <v-card>
          <v-card-title style="align-items: start;">
            <v-col 
              cols="7"
              sm="3"
            >
              <v-select
                v-model="selectYear"
                :items="years"
                item-text="text"
                item-value="value"
                return-object
                @change="changeYear"
                outlined
                hide-details
                dense
                >
              </v-select>
            </v-col>
            <v-col cols="5" style="display: flex; justify-content: flex-end;"
              sm="9"
            >
              <v-icon @click="isOpen = false">mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-divider></v-divider>
          <v-simple-table
            fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" >
                    対象年月
                  </th>
                  <th class="text-left" >
                    送信日時
                  </th>
                  <th class="text-left" >
                    送信結果
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="item.date.unix()"
                  @click="selectItem(index)">
                  <td>
                    {{item.month.format('YYYY/MM')}}
                  </td>
                  <td>
                    {{item.date.format('YYYY/MM/DD HH:mm:ss')}}
                  </td>
                  <td>
                    {{item.errorCode == 0 ? '成功' : 'エラー'}}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    props: ["common", "data", "dialog"],
    components: {
    },
    data: () => ({
      isShow: true,
      isOpen: false,
      selectYear: undefined,
      years: [],
      history: [], 
      items:[],
    }),
    methods: {
      initialize() {
        this.common.eventBus.$emit('App.overflow.remove');
        this.isOpen = true
        this.getSubmitHistory().then((result) => {
          console.log(result)
          this.history = this.sort(result)
          for(let item of this.history) {
            let year = item.month.get('year')
            let yearObj = {text: year + '年', value: year}
            if(!this.years.includes(yearObj)) {
              this.years.push(yearObj)
            }
          }
          this.selectYear = this.years ? this.years[0] : undefined
          this.items = this.filter(this.history, this.selectYear.value)
        })
      },
      selectItem(index) {
        this.dialog.submitHistory = this.items[index]
        this.common.eventBus.$emit('SubmitHistoryDialog.open')
      },
      changeYear() {
        this.items = this.filter(this.history, this.selectYear.value)
      },
      filter(history, year) {
        let result = []
        for (let item of history) {
          if (item.month.get('year') == year) {
            result.push(item)
          }
        }
        return result
      },
      reload() {
        this.isShow = false
        this.isShow = true
      },
      sort(obj) {
        let ret = obj.sort(function(a, b){
          if(a.date.unix() > b.date.unix()) return -1
          if(a.date.unix() < b.date.unix()) return 1
          return 0
        })
        return ret
      },      
    },
    created() {
      this.common.eventBus.$on('SubmitHistory.open', this.initialize);
      this.common.eventBus.$on('SubmitHistory.reload', this.reload);
    },
}
</script>

<style scoped>

</style>
