<template>
  <div v-if="isShow">
    <SideMenu :common="common" :data="data"/>
    <ContentsHeader :common="common" :data="data"/>
    <v-fade-transition>
      <div v-if="isShowCalendar" id="contents">
        <MonthlyView v-if="common.viewmode.isMonthly" :common="common" :data="data" :dialog="dialog"/>
        <ListView v-if="!common.viewmode.isMonthly" :common="common" :data="data" :dialog="dialog"/>
      </div>
    </v-fade-transition>
    <PlusButtons :common="common" :data="data" :dialog="dialog"/>
    <WorkTimeDialog :common="common" :data="data" :dialog="dialog"/>
    <TravelsDialog :common="common" :data="data" :dialog="dialog"/>
    <ExpensesDialog :common="common" :data="data" :dialog="dialog"/>
    <MonthlyStatistics :common="common" :data="data" :dialog="dialog"/>
    <SubmitDialog :common="common" :data="data" :dialog="dialog"/>
    <StandardWorkTimeDialog :common="common" :data="data" :dialog="dialog"/>
    <SubmitSettingDialog :common="common" :data="data" :dialog="dialog"/>
    <CommuterPassList :common="common" :data="data" :dialog="dialog"/>
    <CommuterPassDialog :common="common" :data="data" :dialog="dialog"/>
    <ConfirmDialog :common="common" :data="data" :dialog="dialog"/>
    <SubmitHistory :common="common" :data="data" :dialog="dialog"/>
    <SubmitHistoryDialog :common="common" :data="data" :dialog="dialog"/>
    <HowToUse :common="common"/>
  </div>
</template>

<script>
import moment from 'moment'
import SideMenu from './SideMenu.vue'
import ContentsHeader from './ContentsHeader.vue'
import MonthlyView from './MonthlyView.vue'
import ListView from './ListView.vue'
import PlusButtons from './PlusButtons.vue'
import WorkTimeDialog from './WorkTimeDialog.vue'
import TravelsDialog from './TravelsDialog.vue'
import ExpensesDialog from './ExpensesDialog.vue'
import MonthlyStatistics from './MonthlyStatistics.vue'
import SubmitDialog from './SubmitDialog.vue'
import StandardWorkTimeDialog from './StandardWorkTimeDialog.vue'
import SubmitSettingDialog from './SubmitSettingDialog.vue'
import CommuterPassList from './CommuterPassList.vue'
import CommuterPassDialog from './CommuterPassDialog.vue'
import ConfirmDialog from './ConfirmDialog.vue'
import SubmitHistory from './SubmitHistory.vue'
import SubmitHistoryDialog from './SubmitHistoryDialog.vue'
import HowToUse from './HowToUse.vue'

export default {
  components: {
    SideMenu,
    ContentsHeader,
    MonthlyView,
    ListView,
    PlusButtons,
    WorkTimeDialog,
    TravelsDialog,
    ExpensesDialog,
    MonthlyStatistics,
    SubmitDialog,
    StandardWorkTimeDialog,
    SubmitSettingDialog,
    CommuterPassList,
    CommuterPassDialog,
    ConfirmDialog,
    SubmitHistory,
    SubmitHistoryDialog,
    HowToUse,
  },
  props: ["common"],
  data: () => ({
    isShow: false,
    isShowCalendar: true,
    isCalledApi: false,
    transitionMode: 'left',
    timeOut: undefined,
    startX: undefined,
    endX: undefined,
    startY: undefined,
    endY: undefined,
    isBindedTouchEvent: false,
    dialog: {},
  }),
  methods: {
    initialize() {
      if (this.common.account.token && !this.isCalledApi) {
        this.isCalledApi = true
        this.getAllData(this.common.select.date)
        .then(result => {
          console.log(result)
          this.data = result
          this.common.eventBus.$emit('MonthlyView.initialize')
          this.common.eventBus.$emit('ListView.initialize')
          if (this.data.history.submit.length) {
            this.common.eventBus.$emit('AppHeader.submited')
          } else {
            this.common.eventBus.$emit('AppHeader.notSubmited')
          }
          this.isShow = true
          if(this.getSettingsValue(this.common.settings.standardWorktime.isAutoSet) &&
             this.data.standardWorktime.length == 0) {
            this.setStandardWorkTimeForCurrentMonth()
          }
          let lastCopiedMonth = this.getSettingsValue(this.common.settings.commuterPass.lastCopiedMonth)
          if(this.data.commuterPass.lastMonth.length > 0 && 
             this.data.commuterPass.thisMonth.length == 0 &&
             this.common.select.date.format('YYYY-MM') != lastCopiedMonth) {
            this.copyCommuterPassFromLastMonth()
          }
        })
        .catch(() => {
          this.logout()
        })
        .then(() => {
          this.isCalledApi = false
        })
        let _this = this
        document.addEventListener("visibilitychange", function() {
          if (document.visibilityState == 'visible'){
            _this.getUser()
            .then(() => {
              console.log('This token is valid')
            })
            .catch(err => {
              console.log('This token is invalid')
              _this.errorProcess(err)
            })
          }
        })
      }
    },
    reload() {
      console.log("App.reload")
      if (this.common.account.token && !this.isCalledApi) {
        this.isCalledApi = true
        this.getAllData(this.common.select.date)
        .then(result => {
          console.log(result)
          this.data = result
          this.common.eventBus.$emit('ContentsHeader.reload')
          this.common.eventBus.$emit('MonthlyView.reload')
          this.common.eventBus.$emit('ListView.reload')
          this.common.eventBus.$emit('WorkTimeBottomSheet.reload')
          this.common.eventBus.$emit('TravelsBottomSheet.reload')
          this.common.eventBus.$emit('ExpensesBottomSheet.reload')
          this.common.eventBus.$emit('CommuterPassList.reload')
          if (this.data.history.submit.length) {
            this.common.eventBus.$emit('AppHeader.submited')
          } else {
            this.common.eventBus.$emit('AppHeader.notSubmited')
          }
          this.isBindedTouchEvent = false
          this.isShowCalendar = true        
          this.isShow = false
          this.isShow = true
        })
        .catch(err => {
          this.errorProcess(err)
        })
        .then(() => {
          this.isCalledApi = false
        })
      }
    },
    bindTouchEvent(){
      let contents = document.getElementById("contents")
      if (contents) {
        contents.removeEventListener('touchmove', this.swipe);
        contents.removeEventListener('touchstart', this.swipeStart);
        contents.removeEventListener('touchend', this.swipeEnd);
        contents.addEventListener('touchmove', this.swipe);
        contents.addEventListener('touchstart', this.swipeStart);
        contents.addEventListener('touchend', this.swipeEnd);
      }
      this.isBindedTouchEvent = true
    },
    swipeStart(event){
			this.startX = event.touches[0].pageX;
			this.startY = event.touches[0].pageY;
    },
    swipe(event){
			this.endX = event.touches[0].pageX;
			this.endY = event.touches[0].pageY;
    },
    swipeEnd() {
      if (this.startX !== undefined && this.endX !== undefined) {
        let diffX = this.endX - this.startX
        let diffY = this.endY > this.startY ? this.endY - this.startY : this.startY - this.endY
        if(diffX < -150 && diffY < 20) {
          this.onSwipeLeft()
        } else if(diffX > 150 && diffY < 20) {
          this.onSwipeRight()
        }
      }
      this.startX = undefined
      this.endX = undefined
      this.startY = undefined
      this.endY = undefined
    },
    onSwipeRight() {
      this.slideMonth(-1)
    },
    onSwipeLeft(){
      this.slideMonth(1)
    },
    slideMonth(addMonth) {
      if (this.isShowCalendar) {
        this.isShowCalendar = false
        var date = this.common.select.date
        date.add(addMonth, 'month')
        this.setSelectDate(date.get("year"), date.get("month")+1, date.get("date"))
        this.common.eventBus.$emit('App.reload')
      }
    },
    addOverFlowScroll(){
      document.getElementsByTagName("html")[0].classList.add("overflow-y-scroll")
    },
    removeOverFlowScroll(){
      document.getElementsByTagName("html")[0].classList.remove("overflow-y-scroll")
    },
  },
  computed: {
    styleVariables: function() {
      return {
        '--primaryColor': this.common.color.primary,
        '--secondaryColor': this.common.color.secondary,
        '--tertiaryColor': this.common.color.tertiary,
        '--textColor-default': this.common.color.text.default,
        '--textColor-disabled': this.common.color.text.disabled,
        '--textColor-holiday': this.common.color.text.holiday,
        '--textColor-vacation': this.common.color.text.vacation,
        '--textColor-week-sunday': this.common.color.text.week.sunday,
        '--textColor-week-saturday': this.common.color.text.week.saturday,
        '--borderColor-default': this.common.color.border.default,
        '--backgroundColor-gray': this.common.color.background.gray,
        '--worktimeColor': this.common.color.worktime,
        '--travelColor': this.common.color.travel,
        '--expensesColor': this.common.color.expenses,
        '--commuterpassColor': this.common.color.commuterpass,
        '--selectdayColor': this.common.color.selectday,
      }
    },
  },  
  created() {
    console.log("created")
    this.common.eventBus.$on('App.initialize', this.initialize)
    this.common.eventBus.$on('App.reload', this.reload)
    this.common.eventBus.$on('App.overflow.add', this.addOverFlowScroll)
    this.common.eventBus.$on('App.overflow.remove', this.removeOverFlowScroll)
    this.common.account.token = this.getCookie(this.common.cookie.token)
    let today = moment()
    this.setSelectDate(today.format("YYYY"), today.format("M"), today.format("D"))
    this.initialize()
  },
  updated() {
    if (!this.isBindedTouchEvent) {
      this.bindTouchEvent()
    }
  }
}
</script>

<style>
  html.overflow-y-hidden.overflow-y-scroll {
    overflow-y: scroll !important
  }
</style>