<template>
  <v-main class="monthly-view" v-if="isShow">
    <div
      class="monthly-view__header">
      <div v-for="(dayOfWeek, index) in common.week"
        :key="index"
        style="flex:1;"
        :class="{
          'monthly-view__day--sunday' : index == 0, 
          'monthly-view__day--saturday': index == 6,
        }"
        >
        {{dayOfWeek}}
      </div>
    </div>
    <div
      v-for="(week, index) in calendars"
      :key="index"
      class="monthly-view__week"
    >
      <div
        v-for="(day, index) in week"
        style="display: block"
        :key="index"
        class="monthly-view__day"
        :class="{
          'monthly-view__day--sunday' : day.dayOfWeek == 0 || isHoliday(day.date) || (data.worktimes && data.worktimes[day.day] && data.worktimes[day.day].daytype == 1), 
          'monthly-view__day--saturday': day.dayOfWeek == 6 && !isHoliday(day.date),
          'monthly-view__day--notcurrent': !day.isCurrentMonth,
          'monthly-view__day--today': day.isToday,
          'monthly-view__day--selectday': day.isSelected,
        }"
        @click="selectDate(day)"
      >
        <div 
          class="monthly-view__day__num"
          :class="{'monthly-view__day__num--today': day.isToday}">
          {{ day.day }}
        </div>
        <div v-if="day.isCurrentMonth">
          <div class="monthly-view__worktime-area">
            <v-chip 
              class="monthly-view__worktime" 
              x-small 
              pill 
              dark
              :color="common.color.worktime"
              v-if="data.worktimes && data.worktimes[day.day] && data.worktimes[day.day].daytype != 1 && data.worktimes[day.day].daytype != 2 && data.worktimes[day.day].totaltime">
              {{data.worktimes[day.day].totaltime}}<span class="ml-1">h</span>
            </v-chip>
            <span class="monthly-view__worktime monthly-view__worktime--holiday"
              :class="{
                'monthly-view__worktime--holiday' : data.worktimes[day.day].daytype == 1, 
                'monthly-view__worktime--vacation' : data.worktimes[day.day].daytype == 2, 
              }"
              v-else-if="data.worktimes && data.worktimes[day.day] && (data.worktimes[day.day].daytype == 1 || data.worktimes[day.day].daytype == 2)">
              {{common.daytype[data.worktimes[day.day].daytype]}}
            </span>
            <span class="monthly-view__worktime monthly-view__worktime--holiday"
              :class="{
                'monthly-view__worktime--holiday' : isHoliday(day.date), 
              }"
              v-else>
              {{getHolidayName(day.date)}}
            </span>
          </div>
          <v-avatar
            class="monthly-view__avatar"
            :class="{'mr-1': day.expensesCount}"
            left
            size="20"
            :color="common.color.travel"
            v-if="day.travelsCount">
            {{day.travelsCount}}
          </v-avatar>
          <v-avatar
            class="monthly-view__avatar"
            right
            size="20"
            :color="common.color.expenses"
            v-if="day.expensesCount">
            {{day.expensesCount}}
          </v-avatar>
        </div>
      </div>
    </div>
    <div class="monthly-view__detail">
      <div class="monthly-view__detail__header pt-1 pb-1">
        <div class="monthly-view__detail__header__title ml-3" >
          <span class="monthly-view__detail__header__date">{{common.select.year}}</span>年
          <span class="monthly-view__detail__header__date">{{common.select.month}}</span>月
          <span class="monthly-view__detail__header__date">{{common.select.day}}</span>日
          (
          <span 
            class="monthly-view__detail__header__dayOfWeek"
            :class="{
              'monthly-view__day--sunday': common.select.dayOfWeek == 0 || isHoliday(common.select.date),
              'monthly-view__day--saturday': common.select.dayOfWeek == 6
            }">
            {{common.week[common.select.dayOfWeek]}}
          </span>
          )
        </div>

      </div>
      <div class="monthly-view__detail__contents">
        <v-list class="monthly-view__detail__list ml-1" >
          <WorktimeListItems :common="common" :data="data" :dialog="dialog" :displayItem="selected"/>
          <TravelListItems :common="common" :data="data" :dialog="dialog" :displayItem="selected"/>
          <ExpenseListItems :common="common" :data="data" :dialog="dialog" :displayItem="selected"/>
        </v-list>
      </div>
    </div>    
  </v-main>
</template>

<script>
  import moment from 'moment'
  import WorktimeListItems from './partial/WorktimeListItems.vue'
  import TravelListItems from './partial/TravelListItems.vue'
  import ExpenseListItems from './partial/ExpenseListItems.vue'

  export default {
    components: {
      WorktimeListItems,
      TravelListItems,
      ExpenseListItems,
    },
    props: ["common", "data", "dialog"],
    data: () => ({
      selected: {},
      calendars: [],
      isShow: true,
    }),
    methods: {
      initialize() {
        this.calendars = this.getCalendar()
        this.setSelectedItems()
        this.common.eventBus.$emit('WorktimeListItems.reload')
        this.common.eventBus.$emit('TravelListItems.reload')
        this.common.eventBus.$emit('ExpenseListItems.reload')
        window.scrollTo({top: 0})
      },
      reload() {
        this.calendars = this.getCalendar()
        this.setSelectedItems()
        this.isShow = false
        this.isShow = true
        this.common.eventBus.$emit('WorktimeListItems.reload')
        this.common.eventBus.$emit('TravelListItems.reload')
        this.common.eventBus.$emit('ExpenseListItems.reload')
        this.common.eventBus.$emit('WorkTimeBottomSheet.reload')
        this.common.eventBus.$emit('MonthlyStatistics.reload')
      },
      getStartDate() {
        let date = this.common.select.date.clone()
        date.startOf("month")
        const weekNum = date.day()
        return date.subtract(weekNum, "days")
      },
      getEndDate() {
        let date = this.common.select.date.clone()
        date.endOf("month")
        const weekNum = date.day()
        return date.add(6 - weekNum, "days")
      },
      getCalendar() {
        let startDate = this.getStartDate()
        const endDate = this.getEndDate()
        const weekNumber = Math.ceil(endDate.diff(startDate, "days") / 7)

        let calendars = []
        for (let week = 0; week < weekNumber; week++) {
          let weekRow = []
          for (let day = 0;  day < 7; day++) {
            weekRow.push({
              date: startDate.clone(),
              day: startDate.get("date"),
              dayOfWeek: startDate.format("d"),
              isCurrentMonth: startDate.format("M") == this.common.select.month,
              isToday: startDate.format("YYYYMMDD") == moment().format("YYYYMMDD"),
              isSelected: startDate.format("YYYYMMDD") == this.common.select.date.format("YYYYMMDD"),
              travelsCount: this.getTravelsByDate(startDate).length,
              expensesCount: this.getExpensesByDate(startDate).length,
            })
            startDate.add(1, "days")
          }
          calendars.push(weekRow)
        }
        return calendars
      },
      selectDate(day) {
        if (day.isCurrentMonth) {
          this.setSelectDate(this.common.select.year, this.common.select.month, day.day)
          this.setSelectedItems()
          this.calendars = this.getCalendar()
          this.common.eventBus.$emit('WorktimeListItems.reload')
          this.common.eventBus.$emit('TravelListItems.reload')
          this.common.eventBus.$emit('ExpenseListItems.reload')
        }
      },
      setSelectedItems() {
        this.selected.date = this.common.select.date.clone()
        this.selected.worktimes = this.getWorkTimesByDate(this.common.select.date)
        this.selected.travels = this.getTravelsByDate(this.common.select.date)
        this.selected.expenses = this.getExpensesByDate(this.common.select.date)
      },
    },
    created() {
      this.initialize()
      this.common.eventBus.$on('MonthlyView.initialize', this.initialize)
      this.common.eventBus.$on('MonthlyView.reload', this.reload)
    },
}
</script>

<style scoped>
  .monthly-view {
    margin-top: 110px;
    color:var(--textColor-default);
    text-align: center; 
  }
  .monthly-view__header {
    display:flex;
    border-bottom: 1px solid var(--borderColor-default); 
    font-size: x-small
  }
  .monthly-view__week {
    display:flex;
    border-bottom:1px solid var(--borderColor-default);
  }
  .monthly-view__day {
    flex:1;
    min-height:100px;
  }
  .monthly-view__day--sunday {
    color: var(--textColor-week-sunday);
  }
  .monthly-view__day--saturday {
    color: var(--textColor-week-saturday);
  }
  .monthly-view__day--notcurrent {
    color: var(--textColor-disabled)
  }
  .monthly-view__day--today {
    border: 1px solid var(--secondaryColor);
  }
  .monthly-view__day--selectday {
    background-color: var(--selectdayColor);
  }
  .monthly-view__day__num {
    width: 100%;
    font-size: small;
  }
  .monthly-view__day__num--today {
    font-weight: bold;
  }
  .monthly-view__worktime-area {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 2px;
  }
  .monthly-view__worktime {
    display: flex;
    padding: 0;
    font-size: x-small;
    color: white;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .monthly-view__worktime--holiday {
    font-size: x-small;
    color: var(--textColor-holiday);
  }
  .monthly-view__worktime--vacation {
    font-size: x-small;
    color: var(--textColor-vacation);
  }
  .monthly-view__avatar {
    color: white; font-size: x-small
  }
  .monthly-view__detail__header {
    background-color: var(--backgroundColor-gray);
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .monthly-view__detail__header__title {
    display: inline;
  }
  .monthly-view__detail__header__date {
    font-size: large;
  }
  .monthly-view__detail__contents {
    margin-bottom: 300px;
  }

</style>
