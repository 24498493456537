import moment from 'moment'
import workingHoursApi from './working-hours-api'
import travelingExpensesApi from './traveling-expenses-api'
import expensesApi from './expenses-api'
import passPriceApi from './pass-price-api'
import userApi from './user-api'
import stdWorkingHoursApi from './std-working-hours-api'
import settingsApi from './settings-api'
import holidaysApi from './holidays-api'
import sendMailApi from './sendmail-api'

export default{
  data: () => ({
  }),
  methods: {
    getAllData(date) {
      return new Promise((resolve, reject) => {
        let user = this.getUser();
        let employee = this.getEmployee();
        let worktimes = this.getWorkTimes(date.format("YYYY-MM"));
        let worktimesHistory = this.getWorkTimesHistory();
        let travels = this.getTravels(date.format("YYYY-MM"));
        let travelsHistory = this.getTravelsHistory();
        let expenses = this.getExpenses(date.format("YYYY-MM"));
        let expensesHistory = this.getExpensesHistory();
        let commuterPass = this.getCommuterPass(date.format("YYYY-MM"))
        let commuterPassLastMonth = this.getCommuterPass(date.clone().add(-1, 'month').endOf('month').format("YYYY-MM"))
        let holidays = this.getHolidays(date.format("YYYY"))
        let standardWorktime = this.getStandardWorkTime(date.format("YYYY-MM"));
        let submitHistory = this.getSubmitHistory(date.format("YYYY-MM"), date.format("YYYY-MM"))
        let settings = this.getSettings()
        Promise.all([
          holidays.catch(() => { return [] }), 
          user, 
          employee, 
          worktimes, 
          worktimesHistory, 
          travels,
          travelsHistory,
          expenses, 
          expensesHistory,
          commuterPass,
          commuterPassLastMonth,
          standardWorktime,
          submitHistory,
          settings,
          ])
          .then((result) => {
            let data = this.data || {}
            data.history = {}
            data.holidays = result[0]
            data.user = result[1]
            data.employee = result[2]
            data.worktimes = result[3]
            data.history.worktimes = result[4]
            data.travels = result[5]
            data.history.travels = result[6]
            data.expenses = result[7]
            data.history.expenses = result[8]
            data.commuterPass = {}
            data.commuterPass.thisMonth = result[9]
            data.commuterPass.lastMonth = result[10]
            data.standardWorktime = result[11]
            data.history.submit = result[12]
            data.settings = result[13]
            resolve(data);
          })
          .catch(err => {
            reject(err)
          })
        })
    },
    errorProcess(err) {
      console.log(err)
      let title = undefined
      let message = undefined
      let isLogout = false
      if(err && err.response && err.response.status == 401) {
        title = '認証エラー'
        if (err.response.data && err.response.data == 'Unauthorized') {
          message = 'ユーザーIDまたはパスワードが正しくありません'
        } else if (err.response.data && err.response.data.message == 'jwt expired') {
          message = '認証トークンの有効期限が切れました\n ログイン画面に戻ります'
          isLogout = true
        } else {
          message = '認証に失敗しました\n ログイン画面に戻ります'
          isLogout = true
        }
      } else {
        title = '通信エラー'
        message = 'サーバーとの通信に失敗しました\n しばらく経ってから再度お試しください'
      }
      this.dialog.confirm = {
        title: title,
        message: message,
        ok: "OK",
        okAction: function(){
          if(isLogout) {
            this.logout()
          }
        },
      }
      this.common.eventBus.$emit('ConfirmDialog.open')
    },
    submit(month, address, documents, commnet) {
      return new Promise((resolve, reject) => {
        let promise = []
        if (documents.includes("worktimes")) {
          promise.push(workingHoursApi.excel(month, this.common.account.token))
        }
        if (documents.includes("travels")) {
          promise.push(travelingExpensesApi.excel(month, this.common.account.token))
        }
        if (documents.includes("expenses")) {
          promise.push(expensesApi.excel(month, this.common.account.token))
        }
        Promise.all(promise)
        .then((results) => {
          let excelList = []
          for(let result of results) {
            if (result.data.URL) {
              excelList.push(result.data)
            }
          }
          sendMailApi.send(month, address, commnet, excelList, this.common.account.token)
          .then(() => {
            resolve()
          })
          .catch(err => {
            reject(err)
          })
        })
        .catch(err => {
          reject(err)
        })
    })
    },

    /* worktime */
    getWorkTimes(month) {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        workingHoursApi.get(month, token).then(function (response) {
          resolve(workingHoursApi.toClientSideObjects(response.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    setWorkTime(worktime) {
      return workingHoursApi.set(worktime, this.common.account.token)
    },
    setWorkTimes(worktimes) {
      return workingHoursApi.setMonthlyData(worktimes, this.common.account.token)
    },
    deleteWorkTime(date) {
      return workingHoursApi.delete(date, this.common.account.token)
    },
    getWorkTimesHistory() {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        workingHoursApi.getHistory(token).then(function (response) {
          resolve(workingHoursApi.toClientSideObjectsForHistory(response.data))
        })
        .catch(err => {
          reject(err)
        })
      })
  },
    setWorkTimesHistory(worktime) {
      return workingHoursApi.setHistory(worktime, this.common.account.token)
    },
    deleteWorkTimesHistory(id) {
      return workingHoursApi.deleteHistory(id, this.common.account.token)
    },
    getSameWorkTimesHistory(worktime) {
      if(this.data.history.worktimes) {
        for(let currentWorktime of this.data.history.worktimes) {
          let worktime1 = {}
          let worktime2 = {}
          worktime1.daytype = currentWorktime.daytype
          if (worktime1.daytype == 0) {
            worktime1.starttime = currentWorktime.starttime.format("HH:mm")
            worktime1.endtime = currentWorktime.endtime.format("HH:mm")
            worktime1.breaktime = currentWorktime.breaktime
            worktime1.remarks = currentWorktime.remarks
          } else {
            worktime1.remarks = currentWorktime.remarks
          }
          worktime2.daytype = worktime.daytype
          if (worktime2.daytype == 0) {
            worktime2.starttime = worktime.starttime.format("HH:mm")
            worktime2.endtime = worktime.endtime.format("HH:mm")
            worktime2.breaktime = worktime.breaktime
            worktime2.remarks = worktime.remarks
          } else {
            worktime2.remarks = worktime.remarks
          }
          if (JSON.stringify(worktime1) == JSON.stringify(worktime2)) {
            return currentWorktime
          }
        }
      }
      return undefined
    },
    setStandardWorkTimeForCurrentMonth() {
      let startDate = this.getStartOfMonth();
      let endDate = this.getEndOfMonth();
      let worktimes = []
      let starttime = this.getSettingsValue(this.common.settings.standardWorktime.starttime)
      let endtime = this.getSettingsValue(this.common.settings.standardWorktime.endtime)
      let breaktime = this.getSettingsValue(this.common.settings.standardWorktime.breaktime)
      if (starttime && endtime) {
        for (let day = 0; day < endDate.get("date"); day++) {
          if (startDate.format('d') != 0 && startDate.format('d') != 6 && 
              !this.isHoliday(startDate)) {
            let worktime = {
              date: startDate.clone(),
              daytype: Number(this.getDayTypeIndex('通常')),
              starttime: this.getDateTime(startDate, starttime),
              endtime: this.getDateTime(startDate, endtime),
              breaktime: breaktime,
            }
            if (worktime.endtime.isSameOrBefore(worktime.starttime)) {
              worktime.endtime.add(1, "days")
            }
            worktimes.push(worktime)
          }
          startDate.add(1, "days")
        }
      }
      this.setWorkTimes(worktimes)
      .then(() => {
        let startOfMonth = this.getStartOfMonth()
        let standardWorkTime = {
          month: startOfMonth.format('YYYY-MM'),
          starttime: this.getDateTime(startOfMonth, starttime),
          endtime: this.getDateTime(startOfMonth, endtime),
          breaktime: breaktime,
        }
        if (standardWorkTime.endtime.isSameOrBefore(standardWorkTime.starttime)) {
          standardWorkTime.endtime.add(1, "days")
        }
        this.setStandardWorkTime(standardWorkTime)
        .then(() => {
          this.common.eventBus.$emit('App.reload')
        })
        .catch(err => {
          this.errorProcess(err)
        })
      })
      .catch(err => {
        this.errorProcess(err)
      })
  },

    /* travels */
    getTravels(month) {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        travelingExpensesApi.get(month, token).then(function (response) {
          resolve(travelingExpensesApi.toClientSideObjects(response.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    setTravel(travel) {
      return travelingExpensesApi.set(travel, this.common.account.token)
    },
    deleteTravel(id) {
      return travelingExpensesApi.delete(id, this.common.account.token)
    },
    getTravelsHistory() {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        travelingExpensesApi.getHistory(token).then(function (response) {
          resolve(travelingExpensesApi.toClientSideObjects(response.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    setTravelsHistory(travel) {
      return travelingExpensesApi.setHistory(travel, this.common.account.token)
    },
    deleteTravelsHistory(id) {
      return travelingExpensesApi.deleteHistory(id, this.common.account.token)
    },
    getSameTravelsHistory(travel) {
      if(this.data.history.travels) {
        for(let currentTravel of this.data.history.travels) {
          let travel1 = { ...currentTravel }
          let travel2 = { ...travel }
          travel1.id = undefined
          travel1.date = undefined
          travel2.id = undefined
          travel2.date = undefined
          if (JSON.stringify(travel1) == JSON.stringify(travel2)) {
            return currentTravel
          }
        }
      }
      return undefined
    },

    /* expenses */
    getExpenses(month) {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        expensesApi.get(month, token).then(function (response) {
          resolve(expensesApi.toClientSideObjects(response.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    setExpense(travel) {
      return expensesApi.set(travel, this.common.account.token)
    },
    deleteExpense(id) {
      return expensesApi.delete(id, this.common.account.token)
    },
    getExpensesHistory() {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        expensesApi.getHistory(token).then(function (response) {
          resolve(expensesApi.toClientSideObjects(response.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    setExpensesHistory(travel) {
      return expensesApi.setHistory(travel, this.common.account.token)
    },
    deleteExpensesHistory(id) {
      return expensesApi.deleteHistory(id, this.common.account.token)
    },
    getSameExpensesHistory(expense) {
      if(this.data.history.expenses) {
        for(let currentExpense of this.data.history.expenses) {
          let expense1 = { ...currentExpense }
          let expense2 = { ...expense }
          expense1.id = undefined
          expense1.date = undefined
          expense2.id = undefined
          expense2.date = undefined
          if (JSON.stringify(expense1) == JSON.stringify(expense2)) {
            return currentExpense
          }
        }
      }
      return undefined
    },

    /* commuterPass */
    getCommuterPass(month) {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        passPriceApi.get(month, token).then(function (response) {
          resolve(passPriceApi.toClientSideObjects(response.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    setCommuterPass(commuterPass) {
      return passPriceApi.set(commuterPass, this.common.account.token)
    },
    deleteCommuterPass(id) {
      return passPriceApi.delete(id, this.common.account.token)
    },
    copyCommuterPassFromLastMonth() {
      let promise = []
      if(this.data.commuterPass.lastMonth) {
        for(let commuterPass of this.data.commuterPass.lastMonth) {
          commuterPass.id = undefined
          commuterPass.date.add('month').startOf('month')
          if (commuterPass.amount && commuterPass.amount > 0) {
            promise.push(this.setCommuterPass(commuterPass))
          }
        }
        let settings = {}
        settings.key = this.common.settings.commuterPass.lastCopiedMonth
        settings.value = this.common.select.date.format('YYYY-MM')
        promise.push(this.setSetting(settings))
      }
      return new Promise((resolve, reject) => {
        Promise.all(promise)
        .then(() => {
          this.common.eventBus.$emit('App.reload')
          resolve();
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    /* user */
    getUser() {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        userApi.getUser(token).then(function (response) {
          resolve(response.data[0])
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getEmployee() {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        userApi.getEmployee(token).then(function (response) {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    login(id, password) {
      return userApi.login(id, password)
    },
    logout() {
      this.removeCookie(this.common.cookie.token)
      if (location.pathname != process.env.VUE_APP_LOGIN_PAGE_PATH) {
        this.$router.go({path: this.$router.currentRoute.path, force: false})
      }
    },
    getAddressList(){
      let addresslist = []
      for(let user of this.data.employee) {
        let address = {
          name: user.lastName + user.firstName,
          address: user.email
        }
        addresslist.push(address)
      }
      return addresslist
    },

    /* standardWorktime */
    getStandardWorkTime(month) {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        stdWorkingHoursApi.get(month, token).then(function (response) {
          resolve(stdWorkingHoursApi.toClientSideObjects(response.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    setStandardWorkTime(standardWorktime) {
      return stdWorkingHoursApi.set(standardWorktime, this.common.account.token)
    },
    deleteStandardWorkTime(month) {
      return stdWorkingHoursApi.delete(month, this.common.account.token)
    },

    /* settings */
    getSettings() {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        settingsApi.get(token).then(function (response) {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    setSetting(setting) {
      return settingsApi.set(setting, this.getSettingsValue(setting.key) == undefined, this.common.account.token)
    },
    setSettings(settings) {
      let promise = []
      if(settings) {
        for(let setting of settings) {
          promise.push(this.setSetting(setting))
        }
      }
      return new Promise((resolve, reject) => {
        Promise.all(promise)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    deleteSettings(key) {
      return settingsApi.delete(key, this.common.account.token)
    },
    getSettingsValue(key) {
      if(this.data.settings) {
        for(let setting of this.data.settings) {
          if (setting.key == key) {
            return setting.value
          }
        }
      }
      return undefined
    },
    getNewSettingsObject(key, value) {
      return value && value.length ? {key: key, value: value} : undefined
    },

    /* holidays */
    getHolidays(param) {
      return new Promise(function(resolve) {
        holidaysApi.get(param).then(function (response) {
          resolve(holidaysApi.toHolidays(response.data))
        })
        .catch(() => {
          resolve({})
        })
      })
    },
    toHolidays(holidayList) {
      let holidays = {}
      if (holidayList) {
        for(let holiday of holidayList) {
          holidays[holiday.date] = holiday.name
        }
      }
      return holidays
    },
    isHoliday(date) {
      return this.data.holidays[date.format("YYYY-MM-DD")] != undefined
    },
    getHolidayName(date) {
      return this.data.holidays[date.format("YYYY-MM-DD")]
    },

    /* sendmail-history */
    getSubmitHistory(fromMonth, toMonth) {
      var token = this.common.account.token
      return new Promise(function(resolve, reject) {
        sendMailApi.getHistory(fromMonth, toMonth, token).then(function (response) {
          resolve(sendMailApi.toClientSideObjects(response.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    setSelectDate(year, month, day) {
      let date = moment(new Date(year, month - 1, day))
      this.common.select = {
        date: date,
        year: date.get("year"),
        month: date.get("month") + 1,
        day: date.get("date"),
        dayOfWeek: date.format("d"),
      }
    },
    getStartOfMonth() {
      let date = this.common.select.date.clone()
      date.startOf("month")
      return date
    },
    getEndOfMonth() {
      let date = this.common.select.date.clone()
      date.endOf("month")
      return date
    },
    setCookie(key, value) {
      this.$cookies.set(key, value, 60 * 60 * 24 * 30, "")
    },
    getCookie(key) {
      return this.$cookies.get(key)
    },
    removeCookie(key) {
      return this.$cookies.remove(key)
    },
    getWorkTimesByDate(date) {
      let worktimes = []
      let worktime = this.data.worktimes[date.format("D")]
      if (worktime) {
        worktimes.push(worktime)
      }
      return worktimes;
    },
    getTravelsByDate(date) {
      let travels = [];
      if(this.data.travels) {
        for (let [, travel] of Object.entries(this.data.travels)) {
          if (travel.date.format("YYYYMMDD") == date.format("YYYYMMDD")) {
            travels.push(travel);
          }
        }
      }
      return travels;
    },
    getExpensesByDate(date) {
      let expenses = [];
      expenses.count = 0;
      if(this.data.expenses) {
        for (let [, expense] of Object.entries(this.data.expenses)) {
          if (expense.date.format("YYYYMMDD") == date.format("YYYYMMDD")) {
            expenses.push(expense);
          }
        }
      }
      return expenses;
    },
    getDateTime(date, timeStr) {
      let dateTime = undefined
      if (date && timeStr) {
        dateTime = moment(new Date(date.get("year"), date.get("month"), date.get("date")))
        let hour = timeStr.split(":")[0]
        let minutes = timeStr.split(":")[1]
        dateTime.hour(hour)
        dateTime.minutes(minutes)
      }
      return dateTime
    },
    getBreakTimeList(starttime, endtime) {
      let breaktimeList = ["0"]
      if (starttime && endtime) {
        let date = moment()
        let startDateTime= this.getDateTime(date, starttime)
        let endDateTime= this.getDateTime(date, endtime)
        if (endDateTime.isSameOrBefore(startDateTime)) {
          endDateTime.add(1, "days")
        }
        let diffHour = this.getDiffHour(startDateTime, endDateTime)
        let step = 0.25
        let count = 0
        if (diffHour > 0) {
          let listsize = diffHour / step
          for(let i=0; i<listsize; i++) {
            breaktimeList[i] = count
            count = count + step
          }
        }
      }
      return breaktimeList
    },
    getDiffHour(starttime, endtime) {
      return workingHoursApi.getDiffHour(starttime, endtime)
    },
    getTotalTime(starttime, endtime, breaktime) {
      return workingHoursApi.getTotalTime(starttime, endtime, breaktime)
    },
    allowedStep: m => m % 15 === 0,    
    getDayTypeIndex(dayType) {
      let index = 0
      for(let i in this.common.daytype) {
        if (dayType == this.common.daytype[i]) {
          index = i
          break
        }
      }
      return index
    },
    addOverFlowScroll(){
      document.getElementsByTagName("html")[0].classList.add("overflow-y-scroll")
    },
    removeOverFlowScroll(){
      document.getElementsByTagName("html")[0].classList.remove("overflow-y-scroll")
    },
  },
}