import moment from 'moment'
import abstractApi from './abstract-api'

let API_URL = '/sendmail-api'

let api = {
      send: API_URL + "/send-mail",
      find: API_URL + "/find",
    }

export default{
  send(month, address, comment, excelList, token) {
    var param = {baseMonth : month, address : address, comment : comment, URL : excelList}
    return abstractApi.executeApi(api.send, param, token)
  },
  getHistory(fromMonth, toMonth, token) {
    fromMonth = fromMonth ? fromMonth : '2018-11'
    toMonth = toMonth ? toMonth : moment().format('YYYY') + '-12'
    return abstractApi.executeApi(api.find, {fromMonth: fromMonth, toMonth: toMonth}, token)
  },
  toClientSideObjects(serverSideObjects) {
    let clientSideObjects = []
    if (serverSideObjects) {
      for(let serverSideObject of serverSideObjects) {
        let clientSideObject = {
          id: serverSideObject.sendMailId,
          month: moment(serverSideObject.baseMonth),
          comment: serverSideObject.comment,
          errorCode: serverSideObject.errorCode,
          date: moment(serverSideObject.createDateTime),
          address: serverSideObject.address ? serverSideObject.address.split(',') : [],
          documents: serverSideObject.documents ? serverSideObject.documents.split(',') : [],
        }
        clientSideObjects.push(clientSideObject)
      }
    }
    return clientSideObjects
  },
}