<template>
 <v-app :style="styleVariables">
    <AppHeader :common="common" :data="data"/>
    <router-view  :common="common"/>
  </v-app>
</template>

<script>
import Vue from 'vue'
import AppHeader from './components/AppHeader.vue'

export default {
  components: {
    AppHeader,
  },
  data: () => ({
    data: {},
    common: {
      cookie: {
        token: "asil.token"
      },
      account: {
        id: null,
        name: "アジール 太郎",
        mailaddress: "asil@asil-s.co.jp",
      },
      select: {
        year: undefined,
        month: undefined,        
        day: undefined,
        dayOfWeek: undefined,
      },
      color: {
        primary: "#2196F3",
        secondary: "#BBDEFB",
        tertiary: "#E3F2FD",
        text: {
          default: "#424242",
          disabled: "#E0E0E0",
          alert:"#F44336" ,
          holiday: "#F44336",
          vacation: "#2196F3",
          week: {
            sunday: "#F44336",
            saturday: "#2196F3",
          },
        },
        border: {
          default: "#E0E0E0",
        },
        background: {
          gray: "#EEEEEE",
        },
        worktime: "#78909C",
        travel: "#0091EA",
        expenses: "#00BFA5",
        commuterpass: "#0277BD",
        selectday: "#E1F5FE"
      },
      viewmode: {
        isMonthly: true,
      },
      week: ["日", "月", "火", "水", "木", "金", "土"],
      daytype: ["通常", "休日", "休暇"],
      settings: {
        submit : {
          mailto: "submitSetting.mailto",
          comment: "submitSetting.comment",
        },
        standardWorktime : {
          starttime: "standardWorktime.starttime",
          endtime: "standardWorktime.endtime",
          breaktime: "standardWorktime.breaktime",
          isAutoSet: "standardWorktime.isAutoSet",
        },
        commuterPass: {
          lastCopiedMonth: "commuterPass.lastCopiedMonth"
        }
      },
    },
  }),
  methods: {
  },
  computed: {
    styleVariables: function() {
      return {
        '--primaryColor': this.common.color.primary,
        '--secondaryColor': this.common.color.secondary,
        '--tertiaryColor': this.common.color.tertiary,
        '--textColor-default': this.common.color.text.default,
        '--textColor-disabled': this.common.color.text.disabled,
        '--textColor-holiday': this.common.color.text.holiday,
        '--textColor-vacation': this.common.color.text.vacation,
        '--textColor-week-sunday': this.common.color.text.week.sunday,
        '--textColor-week-saturday': this.common.color.text.week.saturday,
        '--borderColor-default': this.common.color.border.default,
        '--backgroundColor-gray': this.common.color.background.gray,
        '--worktimeColor': this.common.color.worktime,
        '--travelColor': this.common.color.travel,
        '--expensesColor': this.common.color.expenses,
        '--commuterpassColor': this.common.color.commuterpass,
        '--selectdayColor': this.common.color.selectday,
      }
    },
  },  
  created() {
    this.common.eventBus = new Vue()
  },
}
</script>

<style>
</style>